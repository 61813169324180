export default {
    "index.loinText01": "Купить все хорошие вещи в Китае",
    "index.loinText02": "Беспокойная доставка",
    "index.loinText03": "Глобальный уровень",
    "index.loinText04": "Огромное количество китайских товаров · Прямые источники · Многонациональные преференциальные линии",
    "index.retrievePasswordTitle": "Введите почтовый ящик, связанный с вашей учетной записью",
    "index.home01": "Hello, купить / отправить для вас хорошие китайские товары",
    "index.homeBanner01": "Просмотреть следующий заказ",
    "index.homeBanner02": "Отправка заказов",
    "index.homeBanner03": "Перевозка груза",
    "index.homeBanner04": "Пользователь получает",
    "index.homeBannerText01": "Просмотр товаров или представление веб - сайтов для подачи заказа по заказу",
    "index.homeBannerText02": "Платформа принимает заказ, чтобы предоставить вам услуги проверки качества для покупки склада",
    "index.homeBannerText03": "Заявка отправляется в пункт назначения, выбирает способ отправки и оплачивает международную перевозку",
    "index.homeBannerText04": "Прибыл груз. Приятного шопинга.",
    "index.goodProducts": "Хорошее",
    "index.sellingWell": " продается.",
    "index.recommendation": "Рекомендации",
    "index.Selected": "Избранные",
    "index.self-support": "собственный лагерь",
    "index.taobao": "taobao",
	"index.1688": "1688",
	"index.pdd": "pdd",
	"index.jindong": "первазивн",
	"index.Tmall": "кёндон",
    "index.noData": "больше нет данных",
    "index.Loading": "Загрузка...",
    "index.number": "количество",
    "index.ProductRecommendations": "Рекомендуемые товары",
    "index.StartingPurchase": "Приобретение",
    "index.OrderSummary": "Резюме заказов",
    "index.GatheringReproducing": "пpедoстав отправ ещё",
	"index.arrivalWarehouse": "в хранилище, в хранилище",
    "index.DomesticDirectMail": "Самостоятельная прямая почта",
    "index.MoreAddresses": "Больше адресов",
    "index.ProductInfo": "Информация о товарах",
    "index.freight": "Внутренние транспортные расходы",
    "index.unitPrice": "Стоимость единицы",
    "index.notes": "примечание",
	"index.goodsTotal": "общая стоимость товара",
    "index.altogether": "Всего",
    "index.IHaveReadPurchaseInstructions": "Я прочитал и согласился купить",
    "index.orderConfirmText": "* Товар прибывает на склад и отправляется в упаковке сразу же после его прибытия, не дожидаясь других посылок на склад, и груз прибывает быстрее.",
    "index.placeOrder": "Представление заказов",
    "index.LeaveMessage": "Сообщение",
    "index.OptionalFilling": "Выбор",
    "index.TotalExpenses": "Общая сумма расходов",
    "index.ValueAddedServiceFee": "цен обслуживан",
    "index.cashier": "касса",
    "index.paidText01": "Covering 202 countries or regions, supporting 25 currencies. Payment in non US dollar currencies will incur currency exchange and handling fees. Local cooperative banks can provide debit and credit cards",
    "index.paidText02": "Global Supports 25 currencies, with currency exchange and transaction fees incurred for payments in non US dollar currencies Visa, MasterCard, AMEX, JCB",
    "index.paidText03": "Supports RMB payment and requires binding to domestic bank cards in China",
    "index.residue": "излишек",
    "index.paySuccess": "успешная оплата",
    "index.screen": "Отбор",
    "index.ComprehensiveSorting": "Комплексная сортировка",
    "index.salesVolume": "продажи",
	"index.price": "цена",
    "index.Subtotal": "Итого",
    "index.noCart": "В тележке для покупок пусто, так что выбирайте, что хотите！",
    "index.SelectAll": "выбрать",
    "index.AccountBalance": "Сальдо счета",
    "index.obligation": "ждите оплаты",
    "index.beShipped": "готового к отправке",
    "index.beReceived": "оставайтесь на месте",
    "index.myMessage": "Мои новости.",
    "index.MyFavorites": "Моя коллекция.",
    "index.ProductName / MainOrderNumber / SubOrderNumber": "Товарное наименование / основной номер / субномер",
    "index.orderstatus": "Статус заказа",
    "index.starttime": "Время начала",
    "index.EndTime": "Время окончания",
    "index.to": "А",
    "index.obligation": "ждите оплаты",
    "index.Audit": "остаться на рассмотрении",
    "index.Shipped": "поставка завершена",
    "index.Chargeback/Cancellation": "вернут одн/отмен",
    "index.totalAmount": "Всего",
    "index.operation": "Операция",
    "index.OrderNumber": "номер заказа",
	"index.CreationTime": "создание времени",
    "index.detail": "подробн",
    "index.afterSalesDetails": "подробности после продажи",
    "index.dontWant": "больше не хочу",
	"index.priceBit": "немного дороговато",
	"index.styleSpecificationQuantity": "стил/спецификац/количеств снима вин",
	"index.NoNeed": "пока не нужно",
	"index.other": "другие",
    "index.PaymentMethod": "способ оплаты",
    "index.SubmissionTime": "время представления",
    "index.logisticsMessages": "логистические новости",
    "index.logisticsCompany": "логистическая компания",
    "index.LogisticsTrackingNO": "логистическ одн",
    "index.logisticsInfo": "логистическая информация",
    "index.deliveryTime": "время доставки",
	"index.orderTracking": "отслеживание заказов",
    "index.TransactionSerialNumber": "Торговый поток",
    "index.ReceivingInfo": "Информация о получении",
    "index.UserNotes": "Примечания пользователей",
    "index.ShippingLogistics": "Грузоотправительная логистика",
    "index.AdditionalServiceFee": "Дополнительные услуги",
    "index.Service": "услуги",
    "index.SupplementaryPaymentAmount": "Сумма субсидии",
    "index.ReasonPayment": "Причины",
    "index.ApplyingServices": "Заявки на услуги",
    "index.ApplyAfter-salesServiceText": 'Теплый совет: Необоснованная плата за возврат = фрахт, отправленный продавцу + фрахт, отправленный продавцом + фрахт, отправленный продавцом, и фрахт, отправленный продавцу: расчетный фрахт составляет 6 юаней - 25 юаней (конкретная сумма будет взиматься в соответствии с весом и объемом товара и расстоянием доставки в соответствии с тарифом логистической компании); Перевозка от продавца: Упакованные товары оплачиваются вами за возврат почтовых отправлений, неупакованные товары оплачиваются вами за отправку почтовых отправлений и возврат почтовых отправлений, в зависимости от ответа продавца; Оплата услуг по возврату товаров: подробнее см. "Правила предоставления услуг по возврату товаров в течение 5 дней без причины"',
    "index.applicationType": "типы заявок",
	"index.reasonApplication": "причина подачи заявления",
	"index.returnQuantity": "количество возвратов",
	"index.ApplicationDescription": "заявк инструкц",
	"index.ReturnsRefunds": "возврат денег",
	"index.exchangeGoods": "замена",
	"index.refundAmount": "возвратная сумма",
	"index.afterSalesDetails": "подробности после продажи",
    "index.AfterSalesProducts": "Послепродажные товары",
    "index.RefundOnly": "Только возврат",
    "index.UploadCredentials": "Сертификат загрузки",
    "index.productspecifications": "Спецификации товаров",
    "index.itempricing": "Цена за единицу товара",
    "index.AfterSalesInstructions": "Послепродажное описание",
    "index.discoveringProductPassword": "обнаруж товар парол",
    "index.isViewDetails": "вы видите подробности?",
    "index.ContinueShopping": "Продолжайте покупки.",
    "index.contacts": "контакты",
	"index.phone": "телефон",
	"index.country": "страна",
	"index.Province/Province": "провинция/континент",
	"index.city": "город",
	"index.detailAddress": "подробный адрес",
	"index.zipCode": "почтовый индекс",
    "index.location": "Местонахождение",
    "index.PleaseSelect": "Пожалуйста, выберите",
    "index.ModifyAddress": "изменение адреса",
    "index.newAddress": "новый адрес",
    "index.productNameSearch": "Поиск названий товаров",
    "index.date": "Дата",
    "index.isNoData": "Нет данных",
    "index.PosttransactionAmount": "Сумма после сделки",
	"index.transactiontime": "Время торговли",
	"index.transactionnotes": "Примечания к сделкам",
	"index.nickname": "Прозвище",
	"index.Gender": "Пол",
	"index.BirthDate": "Год рождения",
	"index.WeChatAccount": "Микросчет",
	"index.ContactPhoneNumber": "Контактный телефон",
	"index.E-mail": "Электронная почта",
	"index.ModifyAvatar": "Изменить заголовок",
	"index.EditInfo": "Изменить информацию",
    "index.userInfo": "personal information",
	"index.Language": "Язык",
	"index.ChangePassword": "Изменить пароль",
	"index.ClearCache": "Очистить кэш",
	"index.personalInfo": "Личная информация",
	"index.OldPassword": "Старый пароль",
	"index.NewPassword": "Новый пароль",
    "index.transactionNo": "номер транзакций",
    "index.Currency": "монета",

    "menu.overview": "Главная страница",
    "menu.productClass": "Классификация товаров",
    "menu.FreightCalculation": "Расчет транспортных расходов",
    "menu.queryLogistics": "Логистика запросов",
    "menu.helpCenter": "Центр помощи",
    "menu.aboutUs": "О нас",
    "menu.productDetail": "Товары Подробнее",
    "menu.orderConfirm": "Подтверждение заказа",
    "menu.orderConfirm1688": "Подтверждение заказа",
    "menu.bePaid": "оплата заказа",
    "menu.bePaid1688": "оплата заказа",
    "menu.paySuccess": "Оплата успешно",
    "menu.search": "Поиск",
    "menu.user": "Мой.",
    "menu.cart": "Тележка для покупок",
    "menu.favorite": "Коллекция",
    "menu.indentorder": "Заказы по заказу",
    "menu.selfOrder": "Самостоятельный заказ",
    "menu.personalcenter": "Персональный центр",
    "menu.myaccount": "Мой счет",
    "menu.myAddress": "Мой адрес",
    "menu.myWallet": "Мой кошелек",
    "menu.MyTracks": "Мои следы.",

    "btn.Login": "Регистрация",
    "btn.register": "Регистрация",
    "btn.ForgotPassword": "Забыли пароль?",
    "btn.submit": "Представлено",
    "btn.HowShop": "Как делать шопинг",
    "btn.contactUs": "Свяжитесь с нами",
    "btn.MyOrder": "Мой заказ",
    "btn.LogisticsFreight": "логистический фрахт",
    "btn.RulesPolicies": "Правила и политика",
    "btn.LogOut": "Выход из системы",
    "btn.confirm": "Определение",
    "btn.cancel": "Отменить",
    "btn.more": "Больше.",
    "btn.BUYNOW": "покупайте немедленно",
	"btn.AddCart": "присоединяйтесь к тележке",
    "btn.CollectProducts": "Коллекционные товары",
    "btn.CancelFavorite": "Отменить коллекцию",
    "btn.payment": "оплата",
    "btn.NextStep": "Следующий шаг",
    "btn.PutAway": "Убрать",
    "btn.Balance": "Сальдо",
    "btn.CreditCard": "Кредитная карта",
    "btn.WeChatPayment": "WeChat Pay",
    "btn.Alipay": "Платежное сокровище",
    "btn.platform": "платформа",
    "btn.confirm": "подтверждаю",
    "btn.comprehensiveSorting": "комплексное сортирование",
    "btn.SelfGoods": "Самостоятельные товары",
    "btn.taobaoGoods": "Товары Taobao",
    "btn.1688Goods": "Товары 1688",
    "btn.purchasingAgentGoods": "Товары по заказу",
    "btn.BatchDeletion": "Удаление пакетов",
    "btn.ViewMore": "Узнать больше",
    "btn.whole": "все",
    "btn.PendingPayment": "оставаясь в доработке",
    "btn.FreightBePaid": "Неоплаченные транспортные расходы",
    "btn.Completed": "сделано",
    "btn.query": "Запрос",
    "btn.cancellationOrder": "отмена заказа",
    "btn.after-salesService": "после продажи заявки",
    "btn.payment": "Платежи",
    "btn.MakeUpPayment": "Немедленное пополнение",
    "btn.PayShippingFees": "Оплата перевозки",
    "btn.ViewLogistics": "проверьте логистику",
    "btn.signFor": "Подтверждение получения",
    "btn.CancelAfter-salesService": "отмена после продажи",
    "btn.NotCancelledTemporarily": "не отмен",
	"btn.ConfirmCancel": "подтверждаю отмену",
    "btn.Detail": "проверьте подробности",
    "btn.Submit": "Представлено",
    "btn.onApplyAfter-sales": "Повторное обращение после продажи",
    "btn.NotViewedNow": "пока нет",
    "btn.ViewNow": "проверьте немедленно",
    "btn.PleaseLogIn": "Пожалуйста, войдите",
    "btn.viewOrder": "проверьте заказы",
    "btn.homepage": "вернись на первую страницу",
    "btn.addAddress": "Добавить адрес",
    "btn.DefaultAddress": "адрес по умолчанию",
    "btn.SetDefault": "Установить по умолчанию",
    "btn.BatchManagement": "Управление партиями",
    "btn.BatchDelete": "Удаление пакетов",
    "btn.FlowingRecords": "Реестр воды",

    "message.imgCode": "Графический код проверки",
    "message.PleaseEmail": "Введите почтовый ящик",
    "message.PleasePassword": "Введите пароль.",
    "message.PleaseServiceAgreementAndPrivacyPolicy": "Галочка.《Протокол обслуживания》 《Политика конфиденциальности》",
    "message.PleaseComplete": "пожалуйста, введите полный набор！",
    "message.IdentifyCodeError": "Ошибка кода проверки",
    "message.ConfirmPassword": "Подтвердить пароль",
    "message.isPasswordRule": "Пароль состоит из 6 - 20 бит и должен состоять из букв, цифр или символов.",
    "message.TwoPasswordsDoNotMatch": "дважд код не соответств！",
    "message.OperationSuccessful": "операцион успешн",
    "message.isEmailRule": "Введите правильный почтовый ящик.",
    "message.prompt": "Подсказка",
    "message.isLogOut": "Вы уверены, что хотите выйти из реестра?",
    "message.PleaseProductLinkQuery": "Введите ссылку на товар или запрос по имени",
    "message.PleaseKeywords": "Введите ключевое слово",
    "message.PleaseSelectSku": "Пожалуйста, выберите спецификации",
    "message.PleaseSelectAddress": " пожалуйст, выбер адрес",
    "message.PleaseCheckBox": "Пожалуйста, выберите",
    "message.OrderCancelled": "заказ отменен",
    "message.PleaseSelectWarehouse": "пожалуйст, выбер склад",
    "message.PleaseSelectCountry": "пожалуйст, выбер стран",
    "message.cartText01": "Заказы по заказу и заказы по индивидуальному заказу не могут быть подписаны одновременно, пожалуйста, сделайте заказ отдельно.",
    "message.cartText02": "Общая сумма, подлежащая уплате (расходы на международную перевозку учитываются отдельно)",
    "message.DataLoss": "Потерянные данные, пожалуйста, свяжитесь с клиентом",
    "message.PleaseSelectProduct": "пожалуйст, выбер товар",
    "message.PleaseCheckSettled": "прош галочк компенсац товар",
    "message.PleaseSelectCancelOrder": "пожалуйст, выбер отмен заказ причин",
    "message.SuccessfullySubmittedWaitingMerchant": "представ успешн, ждат торговц ",
    "message.isDeleteAddress": "Удалить адрес?",
    "message.PleaseEnter": "пожалуйста, введите",
    "message.PleaseCorrectPhone": "Пожалуйста, введите правильный номер телефона.",
    "message.emailConfirm": "Пожалуйста, пройдите к почтовому ящику для подтверждения",
 }
