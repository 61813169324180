<template>
    <div class="bg_718 color_fff foot_box">
        <div class="container pt_30 pb_30">
            <!-- <div class="foot_row">
                <div class="row">
                  <div class="color_275 size_20 pr_30">{{ $store.state.headOrFootData&&$store.state.headOrFootData.mallTitle }}</div>
                  <el-divider class="color_275" direction="vertical" />
                  <div class="pl_20" v-if="data.form&&data.form.title">{{ data.form.title[$store.state.locale] }}</div>
                </div>
                <div class="flex"></div>
                <div class="pl_30 hand" v-if="data.form&&data.form.tabData" v-for="(itme, i) in data.form.tabData" :key="i" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')">{{ itme[$store.state.locale] }}</div>
            </div>
            <el-divider />
            <div class="min_hide h_40 lh_40 row color_fff">
                <div class="color_275">{{ $store.state.headOrFootData&&$store.state.headOrFootData.webRecords }}</div>
                <div class="pl_30 hand" v-if="data.form&&data.form.tabFootData" v-for="(itme, i) in data.form.tabFootData" :key="i" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')">{{ itme[$store.state.locale] }}</div>
                <div class="flex"></div>
                <img class="hand h_20 ml_10" v-if="data.form&&data.form.imgData" v-for="(itme, i) in data.form.imgData" :key="i" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')" :src="itme[$store.state.locale]" alt="">
            </div>
            <div class="pl_30 min_show">
              <img class="hand h_20 ml_10" v-if="data.form&&data.form.imgData" v-for="(itme, i) in data.form.imgData" :key="i" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')" :src="itme[$store.state.locale]" alt="">
              <div class="row pt_30 pb_30">
                <div class="pr_30 hand" v-if="data.form&&data.form.tabFootData" v-for="(itme, i) in data.form.tabFootData" :key="i" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')">{{ itme[$store.state.locale] }}</div>
              </div>
              <div class="pr_30 color_275">{{ $store.state.headOrFootData&&$store.state.headOrFootData.webRecords }}</div>
            </div> -->
            <div class="display_flex flex_wrap pt_3rem" v-if="data.form">
                <div class="min_flex" :class="{'flex': $store.state.windowWidth>768}" v-for="(itme, i) in data.form.footData" :key="i">
                    <div class="title font_700 hand hover_color_system mb_1rem" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')">{{itme[$store.state.locale]}}</div>
                    <div class="mb_1rem">
                      <div class="li pa_1rem_0 hand hover_color_system" v-for="(li, l) in itme.children" :key="l" @click="onClickImg(li.link&&li.link[$store.state.locale]||'')">{{ li[$store.state.locale] }}</div>
                    </div>
                </div>
                <div class="flex min_show">
                  <img class="h_40 hand hover_color_system" v-if="$store.state.headOrFootData&&$store.state.headOrFootData.mallUrl" @click="openPage('/')" :src="$store.state.headOrFootData.mallUrl" alt="">
                </div>
                <div class="min_w_300">
                    <div class="mb_1rem">
                        <img class="h_40 hand hover_color_system min_hide" v-if="$store.state.headOrFootData&&$store.state.headOrFootData.mallUrl" @click="openPage('/')" :src="$store.state.headOrFootData.mallUrl" alt="">
                    </div>
                    <div class="li row pa_4rem" v-if="data.form.phone">
                        <img class="w_25 h_25 mr_10" v-if="data.form.phone.img" :src="data.form.phone.img[$store.state.locale]" alt="">
                        <div class="flex size_8rem">{{data.form.phone[$store.state.locale]}}</div>
                    </div>
                    <div class="li row" v-if="data.form.mailbox">
                        <img class="w_25 h_25 mr_10" v-if="data.form.mailbox.img" :src="data.form.mailbox.img[$store.state.locale]" alt="">
                        <div class="flex size_8rem">{{data.form.mailbox[$store.state.locale]}}</div>
                    </div>
                    <div class="mb_1rem"></div>
                    <div class="li row pa_4rem gap_10" v-if="data.form.imgData">
                        <img class="w_30 h_30 hand hover_color_system" v-for="(itme, i) in data.form.imgData" :key="i" @click="onClickImg(itme.link&&itme.link[$store.state.locale]||'')" :src="itme[$store.state.locale]" alt="">
                    </div>
                </div>
            </div>
            <div class="mb_1rem pt_3rem size_8rem">{{ $store.state.headOrFootData&&$store.state.headOrFootData.webRecords }}</div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
        data: {}
    }
  },
  watch: {
    '$store.state.bottomHtml'(val) {
      this.data = this.$store.state.bottomHtml
    }
  },
  mounted() {
    setTimeout(() => {
      this.data = this.$store.state.bottomHtml
    }, 200);
  },
}
</script>
<style scoped lang="scss">
.li {
  font-size: .7rem !important;
}
.pa_1rem_0 {
  padding: .1rem 0;
  line-height: 1.6 !important;
}
.size_8rem {
  font-size: .8rem !important;
  line-height: 1.6 !important;
}
.foot_box {
  .foot_row {
    display:flex;
    flex-direction:row;
	  align-items: center;
    height: 60px;
    line-height: 60px;
  }
  .min_hide {
    display: block;
    display:flex;
  }
  .min_show {
    display: none;
  }
}
.pt_3rem {
    padding-top: 3rem;
}
.mb_1rem {
    margin-bottom: 1rem;
}
.title {
    font-size: 1.09375rem !important;
}
.pa_4rem {
    padding: .4rem 0 !important;
}
.min_display_flex {
  display: flex;
}
.min_flex {
  // flex: 1;
  
}
.min_w_300 {
  // width: 250px;
}
@media screen and (max-width: 768px){
  .min_display_flex {
    display: block;
  }
	.foot_box {
    .foot_row {
      display: block;
      height: 100%;
      .row {
        padding-left: 30px;
      }
      
    }
		.min_hide {
      display: none;
    }
    .min_show {
      display: block;
    }
    .min_flex {
      width: 50% !important;
    }
    .min_w_300 {
      width: 50%;
    }
	}
}
</style>
