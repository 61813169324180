import {createStore} from "vuex"
import requestJSON from '../utils/requestJSON'
import { setToken, getToken, removeToken } from '../utils/auth'

export default createStore({
    state: {
      load: false, // 加载
      locale: localStorage.getItem('language') || 'en',
      merchantId: 'wxUmHiT9AlE5tZIpfk',
			appId: 'wxUmHiT9AlE5tZIpfk',
      userInfo: localStorage.getItem('userInfo')&&JSON.parse(localStorage.getItem('userInfo')) || {},
      goodDetails: {}, // 用于复制链接后查询商品详情信息
      headShow: true, // 头部显示隐藏
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      headHeight: 100, // 头部高度
      isLoin: getToken('HPtoken')
    },
    getters: {
        locale: (state, getters) => {
    		return state.locale
  		}

    },
    mutations: {
      updataLODOPData(state, data) {
        state.LODOPData = data
      },
      updataLODOP(state, data) {
        state.LODOP = Object.assign([], data)
      },
	  updataRequest(state, data) {
		state.request = data
	  },
	  updataDb(state, data) {
		state.db = data
	  },
	  updataIndexedDBData(state, data) {
		state.indexedDBData = data
	  },
      setToken(store){
        this.dispatch('getUserInfo', store);
        this.dispatch('getUserMenuInfo', store);
        if(window.localStorage.getItem('tabCheckList')) {
          let data = JSON.parse(window.localStorage.getItem('tabCheckList'))
          data.map(imte=>{
            store.catch_components.push(imte.path)
          })
        }
      },
      // 获取
      async getPathPrint(state) {
        requestJSON({
          url: 'storage-biz/printer_configuration/v1/query_page_using_printer',
          method: 'get',
          params: {
            isWmsMerchant: 1
            // merchantId
          }
        }).then(res => {
          state.loading = false
           if(res.code==0) {
            state.isPrint = true
            state.printObj = res.data
           }
        })
      },
      // 添加
      addKeepAliveCache(state, val) {
        if (state.catch_components.indexOf(val) === -1) {
            state.catch_components.push(val);
        }
      },
      // 删除
      removeKeepAliveCache(state, val) {
       let cache = state.catch_components;
       for (let i = 0; i < cache.length; i++) {
           if (cache[i] === val) {
               cache.splice(i, 1);
           }
       }
       state.catch_components = cache;
      },
    },
    actions: {
      //更新
      async getUserInfo(store){
        requestJSON({
          url: 'admin/user/v1/info',
          method: 'get',
        }).then(res => {
           if(res.code==0) {
            store.state.userData = res.data.sysUser
            store.state.warehouseList = res.data.warehouseList
            if(res.data.selectWarehouse) {
              store.state.selectWarehouse = res.data.selectWarehouse
            } else {
              this.dispatch('getSelectWarehouse', store);
            }
           }
        })
      },
      // 仓库
      getSelectWarehouse(store) {
        requestJSON({
          url: 'admin/user/v1/find_select_warehouse',
          method: 'get',
        }).then(res => {
           if(res.code==0) {
            store.state.selectWarehouse = res.data || {name: ''}
           }
        })
      },
      // 更新
      async getUserMenuInfo(store){
        requestJSON({
          url: 'admin/menu/v1/find_menu_by_user_id',
          method: 'get',
        }).then(res => {
           if(res.code==0) {
            store.state.menuData = res.data
           }
        })
      },
    },
    modules: {}
})