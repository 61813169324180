<template>
  <el-config-provider :locale="locale">
    <div class="app height_100">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script>
//引入vue方法
import { ElConfigProvider } from 'element-plus'
import cn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import i18n from '@/locales'
export default {
  name: 'App',
  components: {
    ElConfigProvider,
  },
  data() {
    return {
      locale: this.$store.state.locale==='cn'? cn:en,
      localeShow: true,
      beforeUnload: '',
      Handler: ''
    }
  },
  created() {
    this.getHomeData()
    this.getData()
    this.getLanguageData()
    if(this.$store.state.userInfo&&this.$store.state.userInfo.userUuid) {
			
		} else {
			// this.getLanguagenAndCurrency() // 获取国家列表
		}
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
     window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
     window.addEventListener('unload', e => this.unloadHandler(e))
    //  setTimeout(()=>{
    //     this.$store.state.headHeight = document.getElementById('myDiv').offsetHeight || 200
    //  }, 500)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    // 监听窗口宽高
    handleResize() {
      this.$store.state.windowWidth = window.innerWidth;
      this.$store.state.windowHeight = window.innerHeight;
      console.log(this.$store.state.windowWidth, 'this.$store.state.windowWidth')
    },
    // 监听是否关闭窗口（拿事件时间戳）
    beforeunloadHandler(){
      this.beforeUnload=new Date().getTime();
   },
   // 监听是否关闭窗口（根据时间戳判断是关闭还是刷新）
   unloadHandler(e){
     this.Handler=new Date().getTime()-this.beforeUnload;
    //  console.log(this.Handler)
        //判断是窗口关闭还是刷新
        if(this.Handler<=5){
        //  console.log('为所欲为');
         localStorage.removeItem('language')
        }
    },
    getData() {
        this.$store.state.loading = true
        this.$requestJSON({
            url: 'merchant_base_setting/v1/findInfo',
            method: 'POST'
        }).then(res => {
            this.$store.state.loading = false
            if (res.code == 200) {
                this.$store.state.headOrFootData = res.data
                let data = res.data
                // 创建新的meta标签并设置描述
                if(data.seoKeyword) {
                  const newMeta = document.createElement('meta');
                  newMeta.setAttribute('name', 'keywords');
                  newMeta.setAttribute('content', data.seoKeyword);
                  document.head.appendChild(newMeta);
                }
                
                if(data.seoTitle) {
                  const description = document.createElement('meta');
                  description.setAttribute('name', 'description');
                  description.setAttribute('content',data.seoTitle);
                  document.head.appendChild(description);
                }
                
                // 设置Twitter Card标签
                document.title = data.mallTitle
            }
        })
    },
    // 获取首页数据
    getHomeData() {
			this.$store.state.load = true
			this.$requestJSON({
				url: 'merchant_base_setting/v1/findHomePage',
				method: 'POST'
			}).then(res => {
				if (res.code === 200) {
					this.$store.state.view = JSON.parse(res.data.homePageHtml)
					this.$store.state.bottomHtml = JSON.parse(res.data.bottomHtml)
					this.$store.state.headHtml = JSON.parse(res.data.headHtml)
				}
			})
		},
    // 获取语言和系统默认语言
    getLanguageData() {
			this.$store.state.load = true
			this.$requestJSON({
				url: 'public/findLanguageAndCurrency',
				method: 'POST'
			}).then(res => {
				if (res.code === 200) {
          let data = res.data.languageSettings
					this.$store.state.languageDatac = data
          if(!localStorage.getItem('language')) {
            this.$store.state.locale = data[0].language
            this.locale = data[0].language
            i18n.global.locale.value = this.locale;
			      localStorage.setItem("language", this.locale)
			      location.reload()
          }
				}
			})
		},
  },
}
</script>

<style>
body {
	width: 100%;
	height: 100%;
}
#app {
  height: 100%;
}
</style>
