import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import locale from 'element-plus/es/locale/lang/zh-cn'
import "element-plus/theme-chalk/index.css"
import 'element-plus/dist/index.css'
import './assets/scss/layout.scss'
import './assets/scss/themes.scss'
import './assets/scss/custom.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "./assets/scss/index.scss"
import mixin from './utils/mixin'
import requestJSON from './utils/requestJSON.js'
import i18n from './locales'
import GoEasy from 'goeasy';
const goEasy = GoEasy.getInstance({
    host: 'hangzhou.goeasy.io',	// 应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
    appkey: 'BC-f6430754d4ae43ff8f63a9caa9a78669', // common key,
    modules: ['im']
});

// createApp(App).mount('#app')
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(ElementPlus)
// app.use(ElementPlus, { locale: locale })
app.use(router)
app.use(i18n)
app.mixin(mixin)
app.use(store)
app.config.globalProperties.$requestJSON = requestJSON
app.config.globalProperties.$elMessageBox = ElMessageBox
// app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$message = {
  error: function(msg) {
      return ElMessage({
        type: 'error',
        message: msg,
        duration: 1500
      })
    },
  success: function(msg) {
      return ElMessage({
        type: 'success',
        message: msg,
        duration: 1500
      })
    },
  warning: function(msg) {
    return ElMessage({
      type: 'warning',
      message: msg,
      duration: 1500
    })
  },
  info: function(msg) {
      return ElMessage({
        type: 'info',
        message: msg,
        duration: 1500
      })
    },
}
app.config.globalProperties.GoEasy = GoEasy
app.config.globalProperties.goEasy = goEasy
app.mount('#app')
