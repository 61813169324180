<template>
    <div v-if="data.form" class="bg_D91 z_index_100 none_ss">
      <div class="container row color_fff head_box">
        <img class="h_40" v-if="$store.state.headOrFootData&&$store.state.headOrFootData.mallUrl" @click="openPage('/')" :src="$store.state.headOrFootData.mallUrl" alt="">
        <div class="pl_10 pr_10 hand size_16 min_hide" @click="openPage('/')" @mouseenter="handleMouseLeave">{{ data.form&&data.form.home&&data.form.home[$store.state.locale] }}</div>
        <!-- <div class="pl_10 pr_10 hand size_16 min_hide" @mouseenter="handleMouseEnter(itme.children)" @click="openPage('/search', {classificationId: itme.id})" v-for="(itme, i) in classList" :key="i">{{itme.name}}</div> -->
        <div class="pl_10 pr_10 hand size_16 min_hide" @mouseenter="handleMouseEnter(classList)">{{ data.form&&data.form.class&&data.form.class[$store.state.locale] }}</div>
        <div class="pl_10 pr_10 hand size_16 min_hide" @click="openPage('/articleList')" @mouseenter="handleMouseLeave">{{ data.form&&data.form.article&&data.form.article[$store.state.locale] }}</div>
        <div class="pl_10 pr_10 hand size_16 min_hide" @click="openPage('/aboutUs')" @mouseenter="handleMouseLeave">{{ data.form&&data.form.aboutUs&&data.form.aboutUs[$store.state.locale] }}</div>
        <div class="flex ml_20 mr_20 tar">
        <el-input
          class="min_hide"
          v-model="title"
          @keyup.enter="getSearch(title)"
          style="width: 80%"
          :placeholder="data.form&&data.form.inputText&&data.form.inputText[$store.state.locale]"
        >
          <template #append><el-icon @click="getSearch(title)"><Search /></el-icon></template>
        </el-input>
      </div>
        <template v-for="(itme, i) in data.form&&data.form.tabData" :key="i">
          <div v-if="itme.isLogin && isLoin&&$route.path !== '/login'" class="pl_10 pr_10 hand min_hide" @click="itme.path? openPage(itme.path):logOut()">{{itme[$store.state.locale]}}</div>
          <div v-if="!itme.isLogin && !isLoin&&$route.path !== '/login'||itme.path === '/articleList'" class="pl_10 pr_10 hand" @click="itme.path==='register'? openPage('/login', {type: 1}):openPage(itme.path)">{{itme[$store.state.locale]}}</div>
          <el-divider class="min_hide" v-if="(itme.isLogin && isLoin&&$route.path !== '/login') || (!itme.isLogin && !isLoin&&$route.path !== '/login') || itme.path === '/articleList'" direction="vertical" />
        </template>
        <!-- <div v-if="$route.path !== '/login' && !isLoin" class="pl_10 pr_10 hand" @click="openPage('/login')">{{$t('btn.Login')}}</div>
        <div v-if="$route.path !== '/login' && isLoin" class="pl_10 pr_10 hand" @click="openPage('/user')">{{$t('menu.user')}}</div>
        <el-divider v-if="$route.path !== '/login'" direction="vertical" />
        <div v-if="$route.path !== '/login' && !isLoin" class="pl_10 pr_10 hand" @click="openPage('/login', {type: 1})">{{$t('menu.register')}}</div>
        <div v-if="$route.path !== '/login' && isLoin" class="pl_10 pr_10 hand" @click="openPage('/cart')">{{$t('menu.cart')}}</div>
        <el-divider v-if="$route.path !== '/login'" direction="vertical" />
        <div v-if="$route.path !== '/login' && isLoin" class="pl_10 pr_10 hand" @click="logOut">{{$t('index.LogOut')}}</div>
        <el-divider v-if="$route.path !== '/login' && isLoin" direction="vertical" /> -->
        <div class="pl_10 hand" @click="getLocales($store.state.locale === 'en'? 'cn':'en')">{{ $store.state.locale === 'en'? 'English':$store.state.locale === 'cn'? '中文CN':$store.state.locale === 'ru'? '俄语RU':'中文CN' }}</div>
      </div>
      <div class="container min_show bg_D91 pb_10 row">
        <el-icon @click="openMinClass" class="size_35 color_fff mr_5 hand"><Expand /></el-icon>
        <div class="flex">
          <el-input
            v-model="title"
            @keyup.enter="getSearch(title)"
            style="width: 100%"
            :placeholder="data.form&&data.form.inputText&&data.form.inputText[$store.state.locale]"
          >
            <template #append><el-icon @click="getSearch(title)"><Search /></el-icon></template>
          </el-input>
        </div>
      </div>
      <div v-if="isProductClass" class="po_a_map z_index_102" :style="`top: ${$store.state.headHeight}px`"></div>
      <div v-if="isProductClass" class="po_f product_class z_index_101" :style="`top: ${$store.state.headHeight}px`">
        
        <ProductClass class="z_index_101" ref="productClass" @handleMouseLeave="handleMouseLeave" :classifyList="classifyList" />
      </div>
      <MinClass ref="minClass" :classifyList="classList" :data="data" />
    </div>
</template>
<script>
import ProductClass from './productClass.vue' // 商品分类
import MinClass from './MinClass.vue' // 商品分类(小屏幕)
import { setToken, getToken, removeToken } from '@/utils/auth'
export default {
  components: {
		ProductClass,
    MinClass
	},
  data () {
    return {
      title: '',
      productClassType: '', // 分类
      isProductClass: false,
      classList: [], // 分类数组
      classifyList: [], // 分类弹窗
      classAll: [],
      data: {}
    }
  },
  watch: {
    $route(val) {
      this.title = ''
      if(val.path.indexOf('/search') !== -1) {
        this.title = this.$route.query.title
      }
    },
    '$store.state.headHtml'(val) {
      this.data = this.$store.state.headHtml&&this.$store.state.headHtml[1]
    },
    '$store.state.isLoin'(val) {
      this.isLoin = val
    }
  },
  created() {
      this.getClassList()
  },
  mounted() {
    setTimeout(() => {
      this.data = this.$store.state.headHtml&&this.$store.state.headHtml[1]
    }, 200);
  },
  methods: {
    // 获取分类数组
    getClassList() {
      this.$store.state.loading = true
			this.$requestJSON({
				url: 'product_category/v1/findTreeList',
				method: 'POST',
			}).then(res => {
				this.$store.state.loading = false
				if (res.code === 200) {
					this.classList = res.data
				}
			})
    },
    handleMouseEnter(data, type) {
      console.log(type, '鼠标移入');
      
      // 这里可以添加鼠标移入时的处理逻辑
      if(type) {
        this.productClassType = type
        this.classAll = data
        this.classifyList = data[0].children
      } else {
        this.productClassType = ''
        this.classAll = []
        this.classifyList = data
      }
      this.isProductClass = true
      
    },
    handleMouseLeave() {
      console.log('鼠标移出');
      // 这里可以添加鼠标移出时的处理逻辑
      this.isProductClass = false
    },
    // 打开小屏幕的分类
    openMinClass() {
      this.$refs.minClass.isShow = true
    },
    // 退出登录
    logOut() {
      this.$elMessageBox.confirm(
          this.$t('message.isLogOut'),
          this.$t('message.prompt'),
        {
          confirmButtonText: this.$t('btn.confirm'),
          cancelButtonText: this.$t('btn.cancel'),
          type: 'warning',
        }
      )
      .then(() => {
        this.$store.state.loading = true
			  this.$requestJSON({
			  	url: 'user_info/v1/logout',
			  	method: 'POST',
			  }).then(res => {
			  	this.$store.state.loading = false
			  	if (res.code === 200) {
			  		this.$store.state.userInfo = {}
            removeToken('HPtoken')
            localStorage.removeItem('userInfo')
            this.openPage('/operate')
            this.isLoin = false
			  	}
			  })
      })
      .catch(() => {
      
      })
    }
  }
}
</script>
<style>
::v-deep .el-input_wrapper.is-focus  {
  box-shadow: 0px 0px 0px #ffffff !important;
}</style>
<style scoped lang="scss">
.po_a_map {
    position: fixed;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  background: rgba(0, 0, 0, 0.4);
  }
.product_class {
  // top: 100px;
  width: 100%;
  left: 0px;
  
}
.el-input-group__append, .el-input-group__prepend {
  background-color: #F9EBD4 !important;
}
::v-deep .el-input-group__append, .el-input-group__prepend {
  // background: #F9EBD4 !important;
}
.head_box {
  width: 100%;
  text-align: center;
  line-height: 60px;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  // padding-right: 20px;
}
.min_show {
  display: none;
}
.top_13 {
  top: 13px;
}

@media screen and (max-width: 870px){
  .head_box {
  	text-align: left;
    line-height: 15px !important;
    // padding: 20px;
    line-height: 50px !important;
    height: 50px !important;
  }
  .min_hide {
    display: none;
  }
  .min_show {
    display: block;
    display:flex;
	  flex-direction:row;
	  align-items: center;
  }
}
@media screen and (max-width: 768px){
  .head_box {
  	text-align: left;
    line-height: 15px !important;
    // padding: 20px;
  }
  .min_hide {
    display: none;
  }
  .min_show {
    display: block;
    display:flex;
	  flex-direction:row;
	  align-items: center;
  }
}
</style>
