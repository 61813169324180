// 判断类型集合
export const checkStr = (str, type) => {
	switch (type) {
		case 'mobile': //手机号码
			return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
		case 'tel': //座机
			return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
		case 'card': //身份证
			return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
		case 'mobileCode': //6位数字验证码
			return /^[0-9]{6}$/.test(str)
		case 'pwd': //密码由6-20位应为字母数字或符号组成
			return /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.])[\da-zA-Z~!@#$%^&*.]{6, 20}$/.test(str)
		case 'payPwd': //支付密码 6位纯数字
			return /^[0-9]{6}$/.test(str)
		case 'postal': //邮政编码
			return /[1-9]\d{5}(?!\d)/.test(str);
		case 'QQ': //QQ号
			return /^[1-9][0-9]{4,9}$/.test(str);
		case 'email': //邮箱
			return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
		case 'money': //金额(小数点2位)
			return /^\d*(?:\.\d{0,2})?$/.test(str);
		case 'URL': //网址
			return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
		case 'IP': //IP
			return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
		case 'date': //日期时间
			return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/
				.test(str)
		case 'number': //数字
			return /^[0-9]$/.test(str);
		case 'english': //英文
			return /^[a-zA-Z]+$/.test(str);
		case 'chinese': //中文
			return /^[\\u4E00-\\u9FA5]+$/.test(str);
		case 'lower': //小写
			return /^[a-z]+$/.test(str);
		case 'upper': //大写
			return /^[A-Z]+$/.test(str);
		case 'HTML': //HTML标记
			return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
		default:
			return true;
	}
}


// 获取两周之前的日期
export const getTwoWeeksAgoDate = () => {
	const today = new Date();
	const twoWeeksAgo = new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000)); // 14天*24小时*60分钟*60秒*1000毫秒
	const twoWeeksAgoYear = twoWeeksAgo.getFullYear();
	const twoWeeksAgoMonth = twoWeeksAgo.getMonth() + 1; // 月份是从0开始的
	const twoWeeksAgoDay = twoWeeksAgo.getDate();
	
	// 格式化输出
	return `${twoWeeksAgoYear}-${twoWeeksAgoMonth.toString().padStart(2, '0')}-${twoWeeksAgoDay.toString().padStart(2, '0')}`;
  }
// 获取一周之前的日期
export const getOneWeeksAgoDate = () => {
	const today = new Date();
	const twoWeeksAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)); // 14天*24小时*60分钟*60秒*1000毫秒
	const twoWeeksAgoYear = twoWeeksAgo.getFullYear();
	const twoWeeksAgoMonth = twoWeeksAgo.getMonth() + 1; // 月份是从0开始的
	const twoWeeksAgoDay = twoWeeksAgo.getDate();
	
	// 格式化输出
	return `${twoWeeksAgoYear}-${twoWeeksAgoMonth.toString().padStart(2, '0')}-${twoWeeksAgoDay.toString().padStart(2, '0')}`;
  }
  
  // 获取当日日期的函数
  export const getCurrentDate = () => {
	const now = new Date();
	const year = now.getFullYear();
	const month = (now.getMonth() + 1).toString().padStart(2, '0');
	const day = now.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
  }

  // 获取昨日日期的函数
  export const getYesterDay = () => {
	var today = new Date();
	var year = today.getFullYear();
	var month = ('0' + (today.getMonth() + 1)).slice(-2);
	var day = ('0' + (today.getDate() - 1)).slice(-2);
	var yesterdayDate = `${year}-${month}-${day}`;
	return yesterdayDate;
  }