<template>
    <div class="height_100 map" v-if="isShow" @click="close">
        <div class="po_r class_box z_index_101 of_auto" @click.stop="">
            <div v-if="isLoin" class="row pa_20" @click.stop="openPage('/minUser')">
                <div class="w_60">
                    <img v-if="$store.state.userInfo.photoUrl" class="w_60 h_60 border_radius_50" :src="$store.state.userInfo.photoUrl" alt="" srcset="">
                    <div v-else class="w_60 h_60 border_radius_50 bg_7F7 color_system lh_60 tac font_500 size_24">{{ $store.state.userInfo.userSurname&&$store.state.userInfo.userSurname.substring(0, 2)||$store.state.userInfo.userName&&$store.state.userInfo.userName.substring(0, 2)||$store.state.userInfo.nickName.substring(0, 2) }}</div>
                </div>
                <div class="flex">
                    <div class="color_000 size_16 pb_5">{{ $store.state.userInfo.userSurname+$store.state.userInfo.userName||$store.state.userInfo.nickName }}</div>
                    <div class="color_666 lh_15 size_12">{{ $store.state.userInfo.email }}</div>
                </div>
                <el-icon><ArrowRight /></el-icon>
                <!-- <el-icon @click="closeReturn" v-if="title"><ArrowLeftBold /></el-icon>
                <div v-if="title">{{ title }}</div>
                <div class="flex"></div>
                <el-icon @click="close" class="size_18"><CloseBold /></el-icon> -->
            </div>
            <div v-if="!isLoin" class="row pa_20" @click.stop="openPage('/login')">
                <div class="w_60">
                    <div class="w_60 h_60 lh_60 border_radius_50 bg_7F7 color_system lh_60 tac font_500 size_24">
                        <el-icon class="size_30 mt_15"><Avatar /></el-icon>
                    </div>
                </div>
                <div class="flex pl_10">{{ $t('btn.PleaseLogIn') }}</div>
                <el-icon><ArrowRight /></el-icon>
            </div>
            <!-- <div v-if="!title" v-for="(itme, i) in classifyList" :key="i" class="h_40 lh_40 hand pl_20 pr_20 row" @click="onClass(i)">
                <img v-if="itme.icon" class="w_40" :src="itme.icon" alt="" srcset="">
                <div class="flex">{{ itme.name }}</div>
                <el-icon><ArrowRight /></el-icon>
            </div> -->
            <div class="h_40 lh_40 pl_20 pr_20 color_999">{{$t('menu.productClass')}}</div>
            <el-collapse v-if="!title" v-model="activeName" accordion>
                <el-collapse-item :title="itme.name" :name="i" v-for="(itme, i) in classifyList" :key="i" class="hand pl_20 pr_20">
                    <el-collapse v-model="activeTweName" accordion>
                        <el-collapse-item :title="li.name" :name="l" v-for="(li, l) in itme.children" :key="i" class="hand pl_20 pr_20">
                            <div @click="openPage('/search', {classificationId: dt.id})" v-for="(dt, t) in li.children" :key="t" class="h_40 lh_40 hand row">
                                <img v-if="dt.icon" class="w_40" :src="dt.icon" alt="" srcset="">
                                <div class="flex">{{ dt.name }}</div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-collapse-item>
            </el-collapse>
            <!-- <template v-if="!title&&data.form" v-for="(itme, i) in data.form&&data.form.tabData" :key="i">
              <div v-if="itme.isLogin && isLoin&&$route.path !== '/login'" class="h_40 lh_40 hand pl_20 pr_20" @click="itme.path? openPage(itme.path):logOut()">{{itme[$store.state.locale]}}</div>
              <div v-if="!itme.isLogin && !isLoin&&$route.path !== '/login'" class="h_40 lh_40 hand pl_20 pr_20" @click="itme.path==='register'? openPage('/login', {type: 1}):openPage(itme.path)">{{itme[$store.state.locale]}}</div>
            </template> -->
            <!-- <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/minUser')">{{$t('menu.personalCenter')}}</div> -->
            <div class="pt_20"></div>
            <div class="h_40 lh_40 pl_20 pr_20 color_999">{{$t('btn.more')}}</div>
            <div class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/articleList')">{{$t('menu.articleList')}}</div>
            <div class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/aboutUs')">{{$t('menu.aboutUs')}}</div>
            
            <div class="pt_20"></div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_40 lh_40 pl_20 pr_20 color_999">{{$t('menu.personalCenter')}}</div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/cart')">{{$t('menu.cart')}}</div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/favorite')">{{$t('menu.favorite')}}</div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/selfOrder')">{{$t('menu.selfOrder')}}</div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/myTracks')">{{$t('menu.MyTracks')}}</div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="openPage('/myAddress')">{{$t('menu.myAddress')}}</div>
            <div v-if="isLoin&&$route.path !== '/login'" class="h_50 lh_50 hand pl_20 pr_20" @click.stop="logOut">{{$t('index.LogOut')}}</div>
            
            
            <el-collapse v-if="title" v-model="activeName" accordion>
                <el-collapse-item :title="itme.name" :name="i" v-for="(itme, i) in classifyList[index].children" :key="i" class="hand pl_20 pr_20">
                    <div v-if="title" @click="openPage('/search', {classificationId: li.id})" v-for="(li, l) in itme.children" :key="i" class="h_40 lh_40 hand row">
                        <img v-if="li.icon" class="w_40" :src="li.icon" alt="" srcset="">
                        <div class="flex">{{ li.name }}</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classifyList: [],
        data: {}
    },
    data () {
        return {
            title: '',
            index: '', // 分类高亮
            activeName: '',
            activeTweName: '',
            isShow: false
        }
    },
    watch: {
        activeName(val) {
            console.log(val, 'val')
        }
    },
    methods: {
        // 点击分类
		onClass(i) {
			this.index = i
            this.title = this.classifyList[i].name
		},
        // 返回上一层
        closeReturn() {
            this.index = ''
            this.title = ''
        },
        // 关闭弹窗
        close() {
            this.isShow = false
        },
        /**
         * 跳转
         */
         openPage(url, data) {
            this.close()
            if(data) {
                this.$router.push({path: url, query: data})
            } else {
                this.$router.push({path:url})
            }
        },
        // 退出登录
        logOut() {
          this.$elMessageBox.confirm(
              this.$t('message.isLogOut'),
              this.$t('message.prompt'),
            {
              confirmButtonText: this.$t('btn.confirm'),
              cancelButtonText: this.$t('btn.cancel'),
              type: 'warning',
            }
          )
          .then(() => {
            this.$store.state.loading = true
	    		  this.$requestJSON({
	    		  	url: 'user_info/v1/logout',
	    		  	method: 'POST',
	    		  }).then(res => {
	    		  	this.$store.state.loading = false
	    		  	if (res.code === 200) {
                        this.openPage('/operate')
                        this.isLoin = false
                        this.$store.state.userInfo = {}
                        this.$store.state.isLoin = false
                        removeToken('HPtoken')
                        localStorage.removeItem('userInfo')
                        this.close()
	    		  	}
	    		  })
          })
          .catch(() => {
        
          })
        }
    }
}
</script>
<style scoped lang="scss">
.class_box {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: 80% !important;
    background: #fff;
    z-index: 1001 !important;
}
::v-deep .el-collapse-item__content {
    padding-bottom: 0px !important;
    border-top: none !important;
}
::v-deep .el-collapse {
    border-bottom: none !important;
    border-top: none !important;
}
::v-deep .el-collapse-item__header {
    border-bottom: none !important;
    border-top: none !important;
}
</style>