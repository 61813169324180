<template>
    <div class="container z_index_101 max_height_100">
        <div class="bg_fff ma_auto width_100 z_index_101" @mouseleave="handleMouseLeave">
            <div class="display_flex height_100 of_auto">
                <div class="w_300 height_100 of_auto">
                    <div v-for="(itme, i) in classifyList" :key="i" class="row pt_15 pb_15 lh_20 pl_10 pr_10 hand bg_hover" :class="{'bg_active': allIndex === i}" @click="onAllClass(i)">
                        <div class="flex color_000 size_16">{{itme.name}}</div>
                        <el-icon><ArrowRight /></el-icon>
                    </div>
                </div>
                <div class="flex height_100 of_auto pt_17">
                    <!-- <div v-if="classifyList.length&&classifyList[index].children&&classifyList[index].children.length" class="mb_20 row flex_wrap mt_10">
                        <div v-for="(li, i) in classifyList[index].children" :key="i" class="h_40 lh_40 pl_15 pr_15 border_radius_5 bg_5F5 ml_10 hand li_active" @click="openPage('/search', {classificationId: li.id, title: li.name})">{{li.name}}</div>
                    </div> -->
                    <div v-if="classifyList.length&&classifyList[index].children&&classifyList[index].children.length" v-for="(li, i) in classifyList[index].children" :key="i" class="mb_20 display_flex flex_wrap">
                       <div class="text_hover color_333 font_700 hand row mr_10 h_25 lh_25" @click="openPage('/search', {classificationId: li.id, title: li.name})">{{ li.name }}<el-icon><ArrowRight /></el-icon></div>
                       <div class="flex flex_wrap display_flex">
                        <div class="text_hover color_666 hand mr_10 lh_25" v-if="li.children&&li.children.length" v-for="(itme, i) in li.children" :key="i" @click="openPage('/search', {classificationId: itme.id, title: itme.name})">{{ itme.name }}</div>
                       </div>
                        <!-- <div class="h_50 lh_50 color_333 size_18 font_600 pl_20">{{li.name}}</div>
                        <div class="display_flex flex_wrap">
                            <div v-if="li.children&&li.children.length" @click="openPage('/search', {classificationId: itme.id})" class="width_16 tac" v-for="(itme, i) in li.children" :key="i">
                                <div class="pl_10"></div>
                                <img class="w_40" :src="itme.icon" alt="" srcset="">
                                <div class="pt_5">{{ itme.name }}</div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="flex">
                    <div class="display_flex">
                        <div v-if="classifyList.length" @click="openPage('/search', {classificationId: itme.id})" class="width_25 row hand mt_10 mb_10" v-for="(itme, i) in classifyList[index].children" :key="i">
                            <div class="pl_10"></div>
                            <img class="w_40" :src="itme.icon" alt="" srcset="">
                            <div class="flex pl_10 size_16 lh_20">{{ itme.name }}</div>
                        </div>
                    </div>
                    
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classifyList: [],
        classAll: [],
        type: ''
    },
    data () {
      return {
        allIndex: 0, // 全部-分类高亮
        index: 0, // 分类高亮
        // type: '', // 默认查2,3级， 'all'查1,2,3级
        // classAll: []
      }
    },
    created() {
        // this.getClassList() // 获取分类
    },
    methods: {
        onAllClass(i) {
            this.allIndex = i
            this.index = i
        },
        // 点击分类
		onClass(i) {
			this.index = i
		},
        handleMouseLeave() {
            this.$emit('handleMouseLeave')
        }
    }
}
</script>
<style scoped lang="scss">
.pt_17 {
    padding-top: 13px;
}
.li_active:hover {
    background-color: #ffebf1 !important;
    color: #ff0f23 !important;
}
.text_hover:hover {
    color: #ff0f23 !important;
}
.bg_active {
    background: linear-gradient(127.85deg, rgba(255, 219, 219, 1) 0%, rgba(255, 253, 253, 1) 100%);
}
.bg_hover:hover {
    background: linear-gradient(127.85deg, rgba(255, 219, 219, 1) 0%, rgba(255, 253, 253, 1) 100%);
}
</style>