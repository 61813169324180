import { createRouter,createWebHashHistory } from "vue-router";
import { getToken } from '@/utils/auth'
import Home from '../views/Home/home.vue'
import Login from '../views/Login/login.vue'
import RetrievePassword from '../views/Login/retrievePassword.vue'
import RetrievePasswordEmail from '../views/Login/retrievePasswordEmail.vue'
import RegisteredSuccess from '../views/Login/registeredSuccess.vue'

let HomeRoutes = []
const routerContext = require.context('./', true, /index\.js$/)
routerContext.keys().forEach(route => {
    if (route.startsWith('./index')) {
        return
    }
    // if(route.indexOf("./user") != -1) {
    //     const routerModule = routerContext(route)
    //     UserRoutes = [...(routerModule.default || routerModule)]
    // } else 
    if (route.indexOf("./home") != -1) {
        const routerModule = routerContext(route)
        HomeRoutes = [...(routerModule.default || routerModule)]
    }
})

const routes = [
  {
      path: '/',
      redirect: "/operate"
  },
  {
      path: '/home',
      name: 'Home',
      component: Home,
      children: [...HomeRoutes]
  },

    {
      path:'/login',
      name: Login,
      component: Login
    },
    {
      path:'/retrievePassword',
      name: RetrievePassword,
      component: RetrievePassword
    },
    {
      path:'/retrievePasswordEmail/:email/:time',
      name: RetrievePasswordEmail,
      component: RetrievePasswordEmail
    },
    {
      path:'/registeredSuccess',
      name: RegisteredSuccess,
      component: RegisteredSuccess
    },
  ]
  const router = createRouter({
    history:createWebHashHistory(),
    routes
  })

  // 路由拦截
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if(!getToken('HPtoken')) {
      if(to.path === '/selfOrder' || to.path === '/cart' || to.path === '/minUser' || to.path === '/user' || to.path === '/myTracks' || to.path === '/favorite' || to.path === '/myAddress' || to.path === '/orderConfirm') {
          next('/login')
      } else {
          next()
      }
  } else {
      next()
  }

})

  export default router
