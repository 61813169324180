<template>
    <div class="bg_6F6 height_100 o_h">
        <div class="bg_fff">
          <div><Header /></div>
        </div>
        <div class="retrieve_box container">
            <div v-if="!isValid" class="flex bg_fff pa_30 border_radius_5 tac">
              <!-- <el-icon class="size_50 color_666"><Link /></el-icon>
              <div class="size_20 color_666">{{$t('message.linkHasExpired')}}</div> -->
              <el-result
                icon="error"
                :title="$t('message.linkHasExpired')"
                sub-title=""
              >
              <template #icon>
                <el-icon class="size_50 color_666"><Link /></el-icon>
              </template>
                <template #extra>
                  <el-button type="primary" @click="openPage('/')">{{$t('btn.homepage')}}</el-button>
                </template>
              </el-result>
            </div>
            <div v-if="isValid" class="flex bg_fff pa_30 border_radius_5">
                <div class="size_24 tac pt_20 pb_20">{{ $t('index.retrievePasswordEmaiTitle') }}</div>
                <div class="w_300 ma_auto">
                    <div class="pt_30">
                        <div class="mb_10 border_radius_5 h_36 lh_36 bg_0F7">
                          <el-input
                            v-model="addForm.password"
                            type="password"
                            :placeholder="$t('message.PleasePassword')"
                            show-password
                          />
                        </div>
                        <div class="mb_10 border_radius_5 h_36 lh_36 bg_0F7">
                          <el-input
                            v-model="addForm.confirmPassword"
                            type="password"
                            :placeholder="$t('message.ConfirmPassword')"
                            show-password
                          />
                        </div>
                    </div>
                    <div class="mb_30 border_radius_5 h_36 lh_36 bg_0F7 row">
                      <el-input v-model="addForm.sidentifyMode" :placeholder="$t('message.imgCode')" clearable />
                      <div class="h_36" @click="refreshCode"><Sidentify v-if="isShow" :identifyCode="identifyCode" /></div>
                    </div>
                    <el-button @click="submit" class="mb_30 width_100" type="primary">{{ $t('btn.Submit') }}</el-button>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Sidentify from '../../components/Sidentify.vue'
import {
	checkStr
} from '@/utils/util.js'
export default {
  name: 'RetrievePassword',
  components: {
    Header,
    Sidentify
  },
  data () {
    return {
        isValid: false, // 是否有效链接
        someDate: new Date(), // 提交申请的时间戳
        identifyCode: '', // 图片验证码
        identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz', // 验证码出现的数字和字母
        isShow: false,
        addForm: {
          registerAccount: '', // 账号
		      password: '', // 密码
          sidentifyMode: '', // 输入框验证码
          confirmPassword: '', // 确认密码
		    }
    }
  },
  created() {
    if(this.$route.params.time) {
      this.someDate = this.$route.params.time
      console.log(this.isWithin30Minutes(), '是否30分钟')
      if(this.isWithin30Minutes()) {
        this.isValid = true
      } else {
        this.isValid = false
      }
    } else {
      this.isValid = false
    }
    if(this.$route.params.email) {
      this.addForm.registerAccount = this.$route.params.email
    } else {
      this.isValid = false
    }
  },
    mounted() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    watch: {
    identifyCode(val) {
      setTimeout(() => {
        this.isShow = true
      }, 100);
    }
  },
  computed: {
    
  },
    methods: {
      isWithin30Minutes() {
      const now = new Date();
      const thirtyMinutesAgo = new Date(now - 30 * 60 * 1000); // 30分钟前的时间
      return this.someDate >= thirtyMinutesAgo && this.someDate <= now;
    },
        // 生成随机数
        randomNum(min, max) {
          max = max + 1
          return Math.floor(Math.random() * (max - min) + min)
        },
        makeCode(o, l) {
          for (let i = 0; i < l; i++) {
            this.identifyCode += o[this.randomNum(0, o.length)]
          }
        },

        // 更新验证码
        refreshCode() {
          this.identifyCode = ''
          this.makeCode(this.identifyCodes, 4)
        },
        // 提交
        submit() {
          if(!this.isWithin30Minutes()) {
            this.$message.error(this.$t('message.linkHasTimedOut'));
            return
          }
          if(!this.addForm.password) {
            this.$message.error(this.$t('message.PleasePassword'));
            return
          }
          if(!this.addForm.confirmPassword) {
            this.$message.error(this.$t('message.ConfirmPassword'));
            return
          }
          if(this.addForm.password.length<6 || this.addForm.password.length>20) {
            this.$message.error(this.$t('message.isPasswordRule'));
				  	return
          }
          if(this.addForm.password !== this.addForm.confirmPassword) {
            this.$message.error(this.$t('message.TwoPasswordsDoNotMatch'));
				  	return
          }
          this.$store.state.loading = true
				  // this.addForm.applyName = this.$route.query.email
				  this.$requestJSON({
				  	url: 'user_info/v1/resetPassword',
				  	method: 'POST',
				  	data: this.addForm
				  }).then(res => {
				  	this.$store.state.loading = false
				  	if (res.code == 200) {
              this.$message.success(this.$t('message.resetSuccessPleaseLogin'));
              this.openPage('/login');
				  	}
				  })
        }
    }
}
</script>
<style scoped lang="scss">
.retrieve_box {
    height: calc(100% - 60px);
    display: flex;
    justify-content: center; // 内容自适应：上下居中
    align-items: center; // 子项对齐方式：左右居中
    ::v-deep .el-input__wrapper {
	  background-color: #EAF0F7 !important;
      border: none !important;
      box-shadow: 0px 0px 0px #ffffff !important;
      height: 36px !important;
      line-height: 36px !important;
		  }
    ::v-deep .el-input__inner {
        box-shadow: 0px 0px 0px #ffffff !important;
        background-color: #EAF0F7 !important;
        width: 100%;
        height: 36px !important;
        line-height: 36px !important;
        border: none !important;
    }
}
</style>