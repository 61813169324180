import Cookies from 'js-cookie'
import store from '@/store'

const TokenKey = 'Token'

export function getToken(name) {
  if (name) {
    return Cookies.get(name)
  } else {
    return Cookies.get(TokenKey)
  }
}

export function setToken(name, token) {
  if(name) {
    return Cookies.set(name, token)
  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function removeToken(name) {
  if(name) {
    store.state.isLogin = false
    return Cookies.remove(name)
  } else {
    return Cookies.remove(TokenKey)
  }
}
