<template>
    <div v-if="data.form" class="head_banner po_f z_index_100">
      <div class="border_c7c border_radius_5 bg_fff box_shadow_20_5 container po_r" :class="{'pt_35 pb_35': $store.state.windowWidth<768, 'pt_15 pb_15 pr_30': $store.state.windowWidth>768}">
        {{ data.form&&data.form.title&&data.form.title[$store.state.locale] }} <span class="hand main_color size_18" @click="getSee( data.form&&data.form.link&&data.form.link[$store.state.locale])">{{ data.form&&data.form.btnText&&data.form.btnText[$store.state.locale] }}</span>
        <el-icon class="hand ml_40 po_a size_25 font_600" @click="close"><Close /></el-icon>
      </div>
    </div>
</template>
<script>
import { setToken, getToken, removeToken } from '@/utils/auth'
export default {
  data () {
    return {
      data: {}
    }
  },
  watch: {
    '$store.state.headHtml'(val) {
      this.data = this.$store.state.headHtml&&this.$store.state.headHtml[0]
    }
  },
  mounted() {
    setTimeout(() => {
      this.data = this.$store.state.headHtml&&this.$store.state.headHtml[0]
    }, 200);
  },
  methods: {
    close() {
      this.$store.state.headShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.head_banner {
  width: 100%;
  text-align: center;
  // line-height: 2em !important;
  // padding-top: 10px;
  // padding-bottom: 10px;
  // padding-right: 20px;
  bottom: 12px;
  .el-icon {
    top: 5px;
    right: 5px;
  }
}
@media screen and (max-width: 768px){
  .head_banner {
  	text-align: left;
    // line-height: 15px !important;
    line-height: 1.2em !important;
    padding: 20px;
  }
}
</style>