/**
 * 
 * @returns home
 */
 const Operate = () => import ( '../../views/Home/operate.vue')
 const Detail = () => import ( '../../views/Home/product/detail.vue')
 const Search = () => import ( '../../views/Home/product/search.vue')
 const SearchType = () => import ( '../../views/Home/product/searchType.vue')
 const Cart = () => import ( '../../views/Home/product/cart.vue')
 const OrderConfirm = () => import ( '../../views/Home/order/orderConfirm.vue')
//  const OrderConfirm1688 = () => import ( '../../views/Home/order/orderConfirm1688.vue')
//  const BePaid = () => import ( '../../views/Home/order/bePaid.vue')
//  const BePaid1688 = () => import ( '../../views/Home/order/bePaid1688.vue')
 const PaySuccess = () => import ( '../../views/Home/order/paySuccess.vue')
 const User = () => import ( '../../views/Home/user/index.vue')
 const UserHome = () => import ( '../../views/Home/user/home.vue')
 const Favorite = () => import ( '../../views/Home/user/favorite.vue')
 const SelfOrder = () => import ( '../../views/Home/user/selfOrder.vue')
 const OrderDetails = () => import ( '../../views/Home/user/orderDetails.vue')
 const AfterService = () => import ( '../../views/Home/user/afterService.vue')
 const AfterSalesDetails = () => import ( '../../views/Home/user/afterSalesDetails.vue')
 const MyAddress = () => import ( '../../views/Home/user/myAddress.vue')
 const MyTracks = () => import ( '../../views/Home/user/myTracks.vue')
 const FlowingRecords = () => import ( '../../views/Home/user/flowingRecords.vue')
 const MyAccount = () => import ( '../../views/Home/user/myAccount.vue')
 const ArticleDetails = () => import ( '../../views/Home/article/articleDetails.vue')
 const ArticleList = () => import ( '../../views/Home/article/articleList.vue')
 const AboutUs = () => import ( '../../views/Home/aboutUs/aboutUs.vue')

 
 export default [{
	    path: '/operate', // 首页概况
	    name: 'menu.overview',
	    component: Operate,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "首页概况",
                path: "/operate"
            }]
        }
	},{
	    path: '/detail/:id', // 商品详情
	    name: 'menu.productDetail',
	    component: Detail,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "商品详情",
                path: "/detail"
            }]
        }
	},{
	    path: '/search', // 搜索
	    name: 'menu.search',
	    component: Search,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "搜索",
                path: "/search"
            }]
        }
	},{
	    path: '/searchType', // 类型搜索
	    name: 'menu.searchType',
	    component: SearchType,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "搜索",
                path: "/searchType"
            }]
        }
	},{
	    path: '/cart', // 购物车
	    name: 'menu.cart',
	    component: Cart,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "购物车",
                path: "/cart"
            }]
        }
	},
    {
	    path: '/orderConfirm', // 订单确认
	    name: 'menu.orderConfirm',
	    component: OrderConfirm,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "订单确认",
                path: "/orderConfirm"
            }]
        }
	},
    // {
	//     path: '/orderConfirm1688', // 订单确认
	//     name: 'menu.orderConfirm1688',
	//     component: OrderConfirm1688,
    //     meta: {
    //         keepAlive: true,
    //         list: [{
    //             requireAuth: true,
    //             name : "订单确认",
    //             path: "/orderConfirm1688"
    //         }]
    //     }
	// },{
	//     path: '/bePaid', // 支付订单
	//     name: 'menu.bePaid',
	//     component: BePaid,
    //     meta: {
    //         keepAlive: true,
    //         list: [{
    //             requireAuth: true,
    //             name : "支付订单",
    //             path: "/bePaid"
    //         }]
    //     }
	// },{
	//     path: '/bePaid1688', // 支付订单
	//     name: 'menu.bePaid1688',
	//     component: BePaid1688,
    //     meta: {
    //         keepAlive: true,
    //         list: [{
    //             requireAuth: true,
    //             name : "支付订单",
    //             path: "/bePaid1688"
    //         }]
    //     }
	// },
    {
	    path: '/paySuccess', // 支付成功
	    name: 'menu.paySuccess',
	    component: PaySuccess,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "支付成功",
                path: "/paySuccess"
            }]
        }
	},
    {
	    path: '/articleDetails/:id', // 文章详情
	    name: 'menu.articleDetails',
	    component: ArticleDetails,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "文章详情",
                path: "/articleDetails/:id"
            }]
        }
	},
    {
	    path: '/articleList', // 文章资讯
	    name: 'menu.articleList',
	    component: ArticleList,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "文章资讯",
                path: "/articleList"
            }]
        }
	},
    {
	    path: '/aboutUs', // 关于我们
	    name: 'menu.aboutUs',
	    component: AboutUs,
        meta: {
            keepAlive: true,
            list: [{
                requireAuth: true,
                name : "关于我们",
                path: "/aboutUs"
            }]
        }
	},
    {
	    path: '/user', // 我的
	    name: 'menu.user',
	    component: User,
        children: [{
            name : "首页",
            path: "",
            component: UserHome,
        },{
            name : "h5首页",
            path: "/minUser",
            component: UserHome,
        },{
            name : "收藏夹",
            path: "/favorite",
            component: Favorite,
        },{
            name : "我的订单",
            path: "/selfOrder",
            component: SelfOrder,
        },{
            name : "订单详情",
            path: "/orderDetails",
            component: OrderDetails,
        },{
            name : "申请售后",
            path: "/afterService",
            component: AfterService,
        },{
            name : "售后详情",
            path: "/afterSalesDetails",
            component: AfterSalesDetails,
        },{
            name : "我的地址",
            path: "/myAddress",
            component: MyAddress,
        },{
            name : "我的足迹",
            path: "/myTracks",
            component: MyTracks,
        },{
            name : "流水记录",
            path: "/flowingRecords",
            component: FlowingRecords,
        },{
            name : "我的账号",
            path: "/myAccount",
            component: MyAccount,
        }],
	}
]