// import { createI18n } from 'vue-i18n'
import i18n from '@/locales'
import { setToken, getToken, removeToken } from '@/utils/auth'

// const { locale } = createI18n();
export default {
    data() {
        return {
			uploadUrl: 'https://www.humanpetsolution.com/pet_shop_service/public/uploadMediaFile', // 上传
			uploadEnclosureUrl: 'https://www.humanpetsolution.com/pet_shop_service/public/uploadMediaFileNew', // 上传附件
			imageUrl: '', //s上传图片数组
			isLoin: getToken('HPtoken'), // 是否登录
			merchantId: 'wxUmHiT9AlE5tZIpfk',
			appId: 'wxUmHiT9AlE5tZIpfk',
			availableBalance: 0, // 账号余额
            locales: '', // 用于逻辑处理
            isPust: true, // 是否加载下一页
			headHeight: 100, // head高度
            current: 1, // 页数
			size: 20, // 每一页数量
			total: 0,
			pageSizes: [20, 50, 100],
			isForYouPust: true, // 推荐列表是否加载下一页
			newGoodData: [], // 最新商品数组
			goodStockData: [], // 现货数组
			minNewGoodData: [], // H5最新商品
			minGoodStockData: [], // h5现货数组
			brandData: [], // 品牌数组
			minBrandData: [], // h5品牌数组
			ODMData: [], // ODM数组
			minODMData: [], // h5ODM数组
			favoritesData: [], // 收藏数组
			addressList: [], // 地址列表
			countryData: [], // 国家列表
			localesData: [{
                value: 'en',
                label: 'English'
            },{
                value: 'cn',
                label: '中文CN'
            }]
			
        }
    },
    watch: {
		'$store.state.userInfo': {
        	// 立即监听
        	immediate: true,
        	// 深度监听
        	deep: true,
        	handler(val){
				if(val.language) {
			  		// this.locales = val&&val.language==='CN'? 'cn':val&&val.language==='EN'? 'en':'cn'
			  		// this.$store.state.locale = val&&val.language==='CN'? 'cn':val&&val.language==='EN'? 'en':'cn'
			  		// localStorage.setItem("language",  this.$store.state.locale)
				}
				// this.isLoin = val&&val.id? true:false
        	}
		}
	},
	created() {
		// this.isLoin = this.$store.state.userInfo&&this.$store.state.userInfo.userUuid? true:false
	},
	mounted() {
		// this.isLoin = getToken('HPtoken')
	},
    methods: {
		// 上传图片
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg'
			const isBMP = file.type === 'image/bmp'
			const isPNG = file.type === 'image/png'
  
			if (!isJPG && !isBMP && !isPNG) {
			  this.$message.error(this.$t('message.imgType'))
			}
			return isJPG || isBMP || isPNG
		  },
		  handleProgress() {
			this.$store.state.loading = true
		  },
		  // 上传失败
		  handleError(res, file) {
			this.$message.error(res.msg);
			this.$store.state.loading = false
		  },
		// 上传成功
		handleAvatarSuccess(res, file) {
		  console.log(file, '上传成功')
		  if(res.code === 200) {
			// this.imageUrl = URL.createObjectURL(file.raw)
			this.imageUrl = res.data[0]
			this.$store.state.loading = false
		  }
		},
		// 删除图片
		handleRemove(file) {
		  console.log(file, '上传失败')
		  this.imageUrl = ''
		},
		// 回到顶部
		scrollToTop() {
			window.scrollTo(0, 0);
		},
         /**
         * 跳转
         */
        openPage(url, data) {
            if(data) {
                this.$router.push({path: url, query: data})
            } else {
                this.$router.push({path:url})
            }
        },
		/**
         * 打开新标签跳转
         */
		openGoodPage(url, data) {
			 // 打开新标签
			 let routeData = ''
			
			 if(data) {
				 // this.$router.push({path: url, query: data})
				 routeData = this.$router.resolve(({
					 path: url, // path 要跳转的路由地址
					 // query 要传递的参数
					 query: data
				 }))
			 } else {
				 // this.$router.push({path:url})
				 routeData = this.$router.resolve(({
					 path: url, // path 要跳转的路由地址
				 }))
			 }
			 window.open(routeData.href,'_blank')
		},
		// 点击首页图片跳转
		onClickImg(link) {
			if(!link) {
				return
			}
			window.open(link,'_blank')
		},
		// 返回上一页
		navBack() {
			this.$router.back();
		},
		// 打开下载文件
		getSee(data, titName) {
			this.onClickImg(data)
			// if(!data) return
			// window.open(data,'_blank')
			// console.log(data, '打开下载文件')
			// // window.open(url, "_blank");
			// if(!data){
			//   return
			// }
			// const content = data
			// const blob = new Blob([content])
			// const fileName = titName?titName: ''
			// if ('download' in document.createElement('a')) { // 非IE下载
			//   const elink = document.createElement('a')
			//   elink.download = fileName
			//   elink.style.display = 'none'
			//   elink.href = URL.createObjectURL(blob)
			//   document.body.appendChild(elink)
			//   elink.click()
			//   URL.revokeObjectURL(elink.href) // 释放URL 对象
			//   document.body.removeChild(elink)
			// } else { // IE10+下载
			//   navigator.msSaveBlob(blob, fileName)
			// }
		},
		// 阻止冒泡
		onStop() {},
		// 选择语言
		getLocales(val) {
			this.locales = val
			this.$store.state.locale = val
			i18n.global.locale.value = val;
			localStorage.setItem("language", val)
			location.reload()
		},
		// 获取国家列表
		getCountryData() {
			this.$store.state.loading = true
			this.$requestJSON({
				url: 'public/global-address',
				method: 'GET',
			}).then(res => {
				this.$store.state.loading = false
				if (res.code === 200) {
					this.countryData = res.data
				}
			})
		},
		// 搜索商品
		getSearch(title) {
			if(title) {
				this.openPage('/search', {title: title})
			}
		},
        // 最新商品
		getNewGoodData() {
			// this.$store.state.load = true
			this.$requestJSON({
				url: 'product/pc/findNewProducts',
				method: 'POST',
				data: {
					current: this.current,
					size: this.size,
					language: this.$store.state.locale
				}
			}).then(res => {
				if (res.code === 200) {
					this.newGoodData = res.data.records
					this.minNewGoodData = [...res.data.records, ...this.minNewGoodData]
					this.total = res.data.total
				}
			})
		},
		// 现货
		getGoodStockData() {
			this.$store.state.load = true
			this.$requestJSON({
				url: 'product/pc/findSpots',
				method: 'POST',
				data: {
					current: this.current,
					size: this.size,
					language: this.$store.state.locale
				}
			}).then(res => {
				this.$store.state.load = false
				if (res.code === 200) {
					this.goodStockData = res.data.records
					this.minGoodStockData = [...res.data.records, ...this.minGoodStockData]
					this.total = res.data.total
				}
			})
		},
		// 品牌
		getBrandData() {
			this.$store.state.load = true
			this.$requestJSON({
				url: 'product/pc/findBrand',
				method: 'POST',
				data: {
					current: this.current,
					size: this.size,
					language: this.$store.state.locale
				}
			}).then(res => {
				this.$store.state.load = false
				if (res.code === 200) {
					this.brandData = res.data.records
					this.minBrandData = [...res.data.records, ...this.minBrandData]
					this.total = res.data.total
				}
			})
		},
		// ODM
		getODMData() {
			this.$store.state.load = true
			this.$requestJSON({
				url: 'product/pc/findOdm',
				method: 'POST',
				data: {
					current: this.current,
					size: this.size,
					language: this.$store.state.locale
				}
			}).then(res => {
				this.$store.state.load = false
				if (res.code === 200) {
					this.ODMData = res.data.records
					this.minODMData = [...res.data.records, ...this.minODMData]
					this.total = res.data.total
				}
			})
		},
		// 我的收藏
		getFavoritesData() {
			this.$store.state.load = true
			this.$requestJSON({
				url: 'product_collect/v1/findPage',
				method: 'POST',
				data: {
					current: this.current,
					size: this.size,
					language: this.$store.state.locale
				}
			}).then(res => {
				if (res.code === 200) {
					this.favoritesData = res.data.records
					this.total = res.data.total
				}
			})
		},
		// 获取地址列表
		getAddressData() {
	    	this.$store.state.loading = true
	    	this.$requestJSON({
	    		url: 'user_address/v1/findList',
	    		method: 'POST'
	    	}).then(res => {
	    		this.$store.state.loading = false
	    		if (res.code === 200) {
	    			this.addressList = res.data
	    		}
	    	})
	    },
		// 获取默认地址
		getDefaultAddress() {
			this.$store.state.loading = true
	    	this.$requestJSON({
	    		url: 'user_address/v1/findDefaultInfo',
	    		method: 'POST'
	    	}).then(res => {
	    		this.$store.state.loading = false
	    		if (res.code === 200) {
	    			this.defaultAddress = res.data || {}
	    		}
	    	})
		},
		// 签收
		getSelfSign(id) {
			this.$elMessageBox.confirm(
				this.$t('message.isSigned'),
				this.$t('message.prompt'),
				{
				  confirmButtonText: this.$t('btn.confirm'),
				  cancelButtonText: this.$t('btn.cancel'),
				  type: 'warning',
				}
			  )
				.then(() => {
				  this.$store.state.loading = true
				  this.$requestJSON({
					  url: 'order/v1/signOrder',
					  method: 'POST',
					  data: {
						  id: id
					  }
				  }).then(res => {
					  // this.$store.state.loading = false
					  if (res.code === 200) {
						this.$message.success(this.$t('message.OperationSuccessful'));
						this.getData()
					  }
				  })
				})
				.catch(() => {
				  
				})
		},
		// 取消订单
		openCancelOrder(id) {
			this.$elMessageBox.confirm(
				this.$t('message.isCancelOrder'),
				this.$t('message.prompt'),
				{
				  confirmButtonText: this.$t('btn.confirm'),
				  cancelButtonText: this.$t('btn.cancel'),
				  type: 'warning',
				}
			  )
				.then(() => {
				  this.$store.state.loading = true
				  this.$requestJSON({
					  url: 'order/v1/cancelOrderPc',
					  method: 'POST',
					  data: {
						  id: id
					  }
				  }).then(res => {
					  // this.$store.state.loading = false
					  if (res.code == 0) {
						this.$message.success(this.$t('message.OperationSuccessful'));
						this.getData()
					  }
				  })
				})
				.catch(() => {
				  
				})
		},
		// 查询用户信息
		getInfo() {
			this.$store.state.loading = true
			this.$requestJSON({
				url: 'user_info/v1/find_info',
				method: 'POST'
			}).then(res => {
				this.$store.state.loading = false
				if (res.code === 200) {
					localStorage.setItem("userInfo", JSON.stringify(res.data))
					this.$store.state.userInfo = res.data
				}
			})
		}
    }
}