<template>
    <div class="min_bg_6F6 height_100 o_h">
        <div class="bg_fff">
          <div><Header /></div>
        </div>
        <div class="none" :class="{'retrieve_box container': $store.state.windowWidth>668}">
            <div class="flex bg_fff pa_30 border_radius_5">
                <div class="size_24 tac pt_20 pb_20">{{ $t('index.retrievePasswordTitle') }}</div>
                <div class="w_300 ma_auto">
                    <div class="pt_30">
                        <div class="mb_10 border_radius_5 h_36 lh_36 bg_0F7">
                          <el-input v-model="addForm.registerAccount" :placeholder="$t('message.PleaseEmail')" clearable />
                        </div>
                    </div>
                    <div class="mb_30 border_radius_5 h_36 lh_36 bg_0F7 row">
                      <el-input v-model="addForm.sidentifyMode" :placeholder="$t('message.imgCode')" clearable />
                      <div class="h_36" @click="refreshCode"><Sidentify v-if="isShow" :identifyCode="identifyCode" /></div>
                    </div>
                    <el-button @click="submit" class="mb_30 width_100" type="primary">{{ $t('btn.Submit') }}</el-button>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Sidentify from '../../components/Sidentify.vue'
import {
	checkStr
} from '@/utils/util.js'
export default {
  name: 'RetrievePassword',
  components: {
    Header,
    Sidentify
  },
  data () {
    return {
        identifyCode: '', // 
        identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz', // 验证码出现的数字和字母
        isShow: false,
        addForm: {
            registerAccount: '', // 账号
            sidentifyMode: '', // 输入框验证码
		    }
    }
  },
  watch: {
    identifyCode(val) {
      setTimeout(() => {
        this.isShow = true
      }, 100);
    }
  },
    mounted() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    methods: {
        // 生成随机数
        randomNum(min, max) {
          max = max + 1
          return Math.floor(Math.random() * (max - min) + min)
        },
        makeCode(o, l) {
          for (let i = 0; i < l; i++) {
            this.identifyCode += o[this.randomNum(0, o.length)]
          }
        },

        // 更新验证码
        refreshCode() {
          this.identifyCode = ''
          this.makeCode(this.identifyCodes, 4)
        },
        // 提交1
        submit() {
          if(!this.addForm.registerAccount || !this.addForm.sidentifyMode) {
			    	this.$message.error(this.$t('message.PleaseComplete'));
			    	return
			    }
          if(!checkStr(this.addForm.registerAccount, 'email')) {
            this.$message.error(this.$t('message.isEmailRule'));
				    return
          }
          if(this.addForm.sidentifyMode != this.identifyCode) {
            this.$message.error(this.$t('message.IdentifyCodeError'));
		    	  return
          }
          this.$store.state.loading = true
			    this.$requestJSON({
			    	url: 'user_info/v1/resetPasswordSendEmail',
			    	method: 'POST',
			    	data: this.addForm
			    }).then(res => {
			    	this.$store.state.loading = false
			    	if (res.code == 200) {
              this.openPage('/registeredSuccess');
			    	}
			    })
        }
    }
}
</script>
<style scoped lang="scss">
.retrieve_box {
    height: calc(100% - 60px);
    display: flex;
    justify-content: center; // 内容自适应：上下居中
    align-items: center; // 子项对齐方式：左右居中
}
.none {
  ::v-deep .el-input__wrapper {
	  background-color: #EAF0F7 !important;
      border: none !important;
      box-shadow: 0px 0px 0px #ffffff !important;
      height: 36px !important;
      line-height: 36px !important;
		  }
    ::v-deep .el-input__inner {
        box-shadow: 0px 0px 0px #ffffff !important;
        background-color: #EAF0F7 !important;
        width: 100%;
        height: 36px !important;
        line-height: 36px !important;
        border: none !important;
    }
}
.min_bg_6F6 {
  background-color: #F6F6F6 !important;
}
@media screen and (max-width: 668px){
  
  .min_hide {
    display: none !important;
  }
  .min_show {
    display: block;
  }
  .min_bg_6F6 {
    background-color: #fff !important;
    
  }
}
</style>