<template>
    <div class="min_bg_6F6 height_100 o_h">
        <div class="bg_fff">
          <HeadBanner v-if="$store.state.headShow" />
          <div><Header /></div>
        </div>
        <div :class="{'row container': $store.state.windowWidth>668}">
          <div class="flex bg_login min_hide">
            <div>
              <!-- <div class="h_100"></div> -->
              <div class="size_26 pb_100">{{ $t('index.loinText01') }}</div>
              <!-- <div class="size_20 pb_60">{{ $t('index.loinText02') }}<span class="size_20 main_color">{{ $t('index.loinText03') }}</span></div> -->
              <div class="size_16">{{ $t('index.loinText04') }}</div>
              <!-- <div class="h_100"></div> -->
            </div>
          </div>
          <div class="flex min_show"></div>
          <div class="min_w_350">
            <div class="min_w_350 pa_30 bg_fff border_radius_10 login_box">
              <div class="mt_10 lh_30 h_30 border_radius_20 bg_6F6 w_160 row">
                <div @click="openType(1)" class="flex tac border_radius_20 hand" :class="{'bg_main_color color_fff': type==1 }">{{ $t('btn.Login') }}</div>
                <div @click="openType(2)" class="flex tac border_radius_20 hand" :class="{'bg_main_color color_fff': type==2 }">{{ $t('btn.register') }}</div>
              </div>
              <div class="pt_20">
                <div class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/xing.png" alt="">
                  <el-input v-model="addForm.registerAccount" :placeholder="$t('message.PleaseEmail')" clearable />
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/name.png" alt="">
                  <el-input
                    v-model="addForm.userSurname"
                    :placeholder="$t('index.surname')"
                  />
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/name.png" alt="">
                  <el-input
                    v-model="addForm.userName"
                    :placeholder="$t('index.ming')"
                  />
                </div>
                <div class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/password.png" alt="">
                  <el-input
                    v-model="addForm.password"
                    type="password"
                    :placeholder="$t('message.PleasePassword')"
                    show-password
                  />
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/confirmPassword.png" alt="">
                  <el-input
                    v-model="addForm.confirmPassword"
                    type="password"
                    :placeholder="$t('message.ConfirmPassword')"
                    show-password
                  />
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/phone.png" alt="">
                  <el-input
                    v-model="addForm.phone"
                    :placeholder="$t('message.PleaseEnterPhone')"
                  />
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/company.png" alt="">
                  <el-input
                    v-model="addForm.company"
                    :placeholder="$t('message.PleaseEnterCompany')"
                  />
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <!-- <el-input
                    v-model="addForm.country"
                    :placeholder="$t('message.PleaseEnterCountry')"
                  /> -->
                  <div class="pl_5"></div>
                  <img class="h_20" src="../../assets/img/country.png" alt="">
                  <el-select filterable v-model="addForm.country" clearable :placeholder="$t('message.PleaseEnterCountry')">
                    <el-option v-for="(itme, i) in countryData" :key="i" :label="itme.name" :value="itme.name" />
                  </el-select>
                </div>
                <div v-if="type===2" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <el-input
                    v-model="addForm.activeCode"
                    :placeholder="$t('message.VerificationCode')"
                  />
                  <div class="pl_10 bg_fff height_100"></div>
                  <el-button v-if="sun===0" @click="getSendCode" type="primary">{{$t('btn.SendCode')}}</el-button>
                  <el-button v-if="sun!==0" type="info" plain disabled>{{sun}} s {{$t('btn.RetrieveAgain')}}</el-button>
                </div>
                <div v-if="type===1" class="mb_10 border_radius_5 h_36 lh_36 bg_0F7 row">
                  <el-input v-model="addForm.sidentifyMode" :placeholder="$t('message.imgCode')" clearable />
                  <div class="h_36" @click="refreshCode"><Sidentify v-if="isShow" :identifyCode="identifyCode" /></div>
                </div>
                <div v-if="type===1" @click="openPage('/retrievePassword')" class="mb_10 tar hand">{{ $t('btn.ForgotPassword') }}</div>
                <div v-if="type===2" class="mb_10 pt_10 row">
                  <el-icon @click="addForm.isAgree=!addForm.isAgree" :size="20" class="hand size_20" :class="addForm.isAgree? 'main_color':'color_079'"><CircleCheckFilled /></el-icon>
                  <div class="flex color_079 pl_5" v-if="$store.state.locale === 'cn'">我已阅读并同意<span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.userAgreement, '用户协议')">《用户协议》</span>和<span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.privacyAgreement, '隐私协议')">《隐私协议》</span></div>
				          <div class="flex color_079 pl_5" v-else-if="$store.state.locale === 'en'">I have read and agreed to the <span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.userAgreementEn, 'User Agreement')">User Agreement</span> and <span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.privacyAgreementEn, 'Privacy Agreement')">Privacy Agreement</span></div>
                </div>
                <el-button @click="submit" class="width_100" type="primary">{{ type===1? $t('btn.Login'):$t('btn.register') }}</el-button>
                <div v-if="type===1" class="mt_30 row">
                  <el-icon @click="addForm.isAgree=!addForm.isAgree" :size="20" class="hand size_20" :class="addForm.isAgree? 'main_color':'color_079'"><CircleCheckFilled /></el-icon>
                  <div class="flex color_079 pl_5" v-if="$store.state.locale === 'cn'">我已阅读并同意<span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.userAgreement, '用户协议')">《用户协议》</span>和<span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.privacyAgreement, '隐私协议')">《隐私协议》</span></div>
				          <div class="flex color_079 pl_5" v-else-if="$store.state.locale === 'en'">I have read and agreed to the <span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.userAgreementEn, 'User Agreement')">User Agreement</span> and <span class="main_color hand" @click="getSee($store.state.headOrFootData&&$store.state.headOrFootData.privacyAgreementEn, 'Privacy Agreement')">Privacy Agreement</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex min_show"></div>
        </div>
    </div>
</template>
<script>
import HeadBanner from '../../components/HeadBanner.vue'
import Header from '../../components/Header.vue'
import Sidentify from '../../components/Sidentify.vue'
import { setToken, getToken, removeToken } from '@/utils/auth'
import {
	checkStr
} from '@/utils/util.js'
export default {
  name: 'Login',
  components: {
    HeadBanner,
    Header,
    Sidentify
  },
  data () {
    return {
      type: 1, // 1:登录 2：注册
      identifyCode: '', // 
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz', // 验证码出现的数字和字母
      isShow: false,
      sun: 0, // 倒计时
      time: '', // 定时器
      addForm: {
				registerAccount: '', // 账号
				password: '', // 密码
				registrationType: 1, // 1-邮箱+密码，2-手机号
        sidentifyMode: '', // 输入框验证码
        isAgree: false, // 是否勾选

        confirmPassword: '', // 确认密码(注册)
        userSurname: '', // 姓
        userName: '', // 名
        phone: '', // 手机号
        company: '', // 公司
        country: '', // 国家
        activeCode: '', // 注册验证码
			}
    }
  },
  created() {
    this.getCountryData() // 获取国家
    if(this.$route.query.type) {
      this.type = 2
    }
  },
  mounted() {
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  watch: {
    identifyCode(val) {
      setTimeout(() => {
        this.isShow = true
      }, 100);
    }
  },
  methods: {
    // 切换
    openType(type) {
      this.type = type
      this.addForm = {
        registerAccount: '', // 账号
				password: '', // 密码
				registrationType: 1, // 1-邮箱+密码，2-手机号
        sidentifyMode: '', // 输入框验证码
        isAgree: false, // 是否勾选

        confirmPassword: '', // 确认密码(注册)
        userSurname: '', // 姓
        userName: '', // 名
        phone: '', // 手机号
        company: '', // 公司
        country: '', // 国家
      }
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += o[this.randomNum(0, o.length)]
      }
    },
    
    // 更新验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 发送注册验证码
    getSendCode() {
      if(!this.addForm.registerAccount) {
				this.$message.error(this.$t('message.PleaseEnterEmail'), 'error');
				return
			}
      if(!checkStr(this.addForm.registerAccount, 'email')) {
        this.$message.error(this.$t('message.isEmailRule'));
			  return
      }
      this.$store.state.loading = true
      this.$requestJSON({
				url: 'user_info/v1/send_email',
				method: 'POST',
				data: {
          registrationType: 1,
          registerAccount: this.addForm.registerAccount
        }
			}).then(res => {
				this.$store.state.loading = false
				if (res.code == 200) {
          this.$message.success(this.$t('message.EmailHasBeen'));
          this.sun = 120
          this.time = setInterval(() => {
            this.sun --
            if(this.sun === 0) {
              clearInterval(this.time)
            }
          }, 1000);
				}
			})
    },
    // 登录/注册
    submit() {
      if(!this.addForm.isAgree) {
				this.$message.error(this.$t('message.PleaseServiceAgreementAndPrivacyPolicy'), 'error');
				return
			}
      if(this.type === 1) {
        this.login()
      } else {
        this.register()
      }
    },
    // 登录
    login() {
				if(!this.addForm.registerAccount || !this.addForm.password || !this.addForm.sidentifyMode) {
					this.$message.error(this.$t('message.PleaseComplete'));
					return
				}
        if(!checkStr(this.addForm.registerAccount, 'email')) {
          this.$message.error(this.$t('message.isEmailRule'));
				  return
        }
        if(this.addForm.sidentifyMode !== this.identifyCode) {
          this.$message.error(this.$t('message.IdentifyCodeError'));
					return
        }
				this.$store.state.loading = true
				this.addForm.applyName = this.$store.state.appName
				this.$requestJSON({
					url: 'user_info/v1/user_login',
					method: 'POST',
					data: this.addForm
				}).then(res => {
					this.$store.state.loading = false
					if (res.code == 200) {
            localStorage.setItem("userInfo", JSON.stringify(res.data))
            this.$store.state.userInfo = res.data
            // this.$store.state.locale = res.data.language==='CN'? 'cn':res.data.language==='EN'? 'en':res.data.language==='RU'? 'ru':'cn'
            // localStorage.setItem("language", this.$store.state.locale)
				    setToken('HPtoken', res.data&&res.data.tokenId)
						this.openPage('/')
					}
				})
			},
      // 注册
      register() {
        if(!this.addForm.registerAccount || !this.addForm.password || !this.addForm.confirmPassword || !this.addForm.activeCode || (!this.addForm.userName&&this.type===2)) {
					// this.$message.error(this.$t('message.PleaseComplete'));
          if(!this.addForm.registerAccount) {
            this.$message.error(this.$t('message.PleaseEmail'));
            return
          }
          if(!this.addForm.userSurname&&this.type===2) {
            this.$message.error(this.$t('message.PleaseEnterName'));
            return
          }
          if(!this.addForm.userName&&this.type===2) {
            this.$message.error(this.$t('message.PleaseEnterName'));
            return
          }
          if(!this.addForm.password) {
            this.$message.error(this.$t('message.PleasePassword'));
            return
          }
          if(!this.addForm.confirmPassword) {
            this.$message.error(this.$t('message.ConfirmPassword'));
            return
          }
          if(!this.addForm.activeCode) {
            this.$message.error(this.$t('message.PleaseEnterVerificationCode'));
            return
          }
				}
        if(!checkStr(this.addForm.registerAccount, 'email')) {
          this.$message.error(this.$t('message.isEmailRule'));
				  return
        }
        // if(!checkStr(this.addForm.password, 'pwd')) {
        //   this.$message.error(this.$t('message.isPasswordRule'));
				// 	return
        // }
        if(this.addForm.password.length<6 || this.addForm.password.length>20) {
          this.$message.error(this.$t('message.isPasswordRule'));
					return
        }
        if(this.addForm.password !== this.addForm.confirmPassword) {
          this.$message.error(this.$t('message.TwoPasswordsDoNotMatch'));
					return
        }
        this.$store.state.loading = true
				this.addForm.applyName = this.$store.state.appName
        this.addForm.personalEmail = this.addForm.registerAccount
				this.$requestJSON({
					url: 'user_info/v1/registration',
					method: 'POST',
					data: this.addForm
				}).then(res => {
					this.$store.state.loading = false
					if (res.code == 200) {
            this.$message.success(this.$t('message.registeredSuccess'));
            this.openType(1)
            // this.openPage('/registeredSuccess');
					}
				})
      }
  }
}
</script>
<style scoped lang="scss">
body {
	width: 100%;
	height: 100%;
  .min_bg_6F6 {
    height: 100%;
    .bg_login {
        background-image: url('../../assets/img/bg_login@3x.png');
        background-size: 105% 120%;
        height: 100%;
        display: flex;
        justify-content: center; // 内容自适应：上下居中
        align-items: center; // 子项对齐方式：左右居中
      }
      .login_box {
        box-shadow: 0px 20px 60px  rgba(123, 61, 224, 0.1);
        ::v-deep .el-input__wrapper {
			  	background-color: #EAF0F7 !important;
          border: none !important;
          box-shadow: 0px 0px 0px #ffffff !important;
          height: 36px !important;
          line-height: 36px !important;
			  }
        ::v-deep .el-input__inner {
            box-shadow: 0px 0px 0px #ffffff !important;
            background-color: #EAF0F7 !important;
            width: 100%;
            height: 36px !important;
            line-height: 36px !important;
            border: none !important;
        }
        ::v-deep .el-select__wrapper {
          box-shadow: 0px 0px 0px #ffffff !important;
          background-color: #EAF0F7 !important;
          width: 100%;
          height: 36px !important;
          line-height: 36px !important;
          border: none !important;
        }
        .login_item {
          box-shadow: 0px 10px 13px  rgba(0, 0, 0, 0.1);
        }
      }
    .row {
      height: calc(100% - 100px);
      // height: 100%;
      width: 100%;
    }
  }
}
.min_show {
  display: none;
}
.min_hide {
    display: block;
}
.min_w_350 {
  width: 350px;
}
.min_bg_6F6 {
  background-color: #F6F6F6 !important;
}
@media screen and (max-width: 668px){
  
  .min_hide {
    display: none !important;
  }
  .min_show {
    display: block;
  }
  .min_w_350 {
    width: 100%;
    height: 100%;
  }
  .container {
    background-color: #fff !important;
  }
  .min_bg_6F6 {
    background-color: #fff !important;
    height: 100%;
    .login_box {
      box-shadow: none !important;
      .login_item {
        box-shadow: none !important;
      }
    }
    
    .row {
      // background-color: #fff !important;
    }
  }
}
</style>
