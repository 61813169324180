<template>
     <div class="bg_6F6 height_100 o_h">
        <div class="bg_fff">
          <div><Header /></div>
        </div>
        <div class="retrieve_box container">
            <div v-if="!isValid" class="flex bg_fff pa_30 border_radius_5 tac">
              <!-- <el-icon class="size_50 color_666"><Link /></el-icon>
              <div class="size_20 color_666">{{$t('message.linkHasExpired')}}</div> -->
              <el-result
                icon="success"
                :title="$t('message.EmailHasBeen')"
                sub-title=""
              >
                <template #extra>
                  <el-button @click="openPage('/')">{{$t('btn.homepage')}}</el-button>
                  <el-button type="primary" @click="openPage('/login')">{{$t('btn.goLogin')}}</el-button>
                </template>
              </el-result>
            </div>
        </div>
     </div>
</template>
<script>
import Header from '../../components/Header.vue'
export default {
    components: {
      Header,
    },
    data () {
        return {}
    },
    methods: {}
}
</script>
<style scoped lang="scss">
.retrieve_box {
    height: calc(100% - 200px);
    display: flex;
    justify-content: center; // 内容自适应：上下居中
    align-items: center; // 子项对齐方式：左右居中
    ::v-deep .el-input__wrapper {
	  background-color: #EAF0F7 !important;
      border: none !important;
      box-shadow: 0px 0px 0px #ffffff !important;
      height: 36px !important;
      line-height: 36px !important;
		  }
    ::v-deep .el-input__inner {
        box-shadow: 0px 0px 0px #ffffff !important;
        background-color: #EAF0F7 !important;
        width: 100%;
        height: 36px !important;
        line-height: 36px !important;
        border: none !important;
    }
}
</style>