import store from '@/store'
import router from '@/router'
// import Vue from 'vue'
import qs from 'qs'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { removeToken, getToken } from '@/utils/auth'
// getToken
// import serverConfig from '@/serverConfig'


// create an axios instance

let url =  window.location.href;
let baseURL = ''
if (url.indexOf('//47.113.195.232/') !== -1) {
  baseURL = 'http://47.113.195.232:8188/'
} else if (url.indexOf('//sshop.babalish.com/') !== -1) {
  // baseURL = 'https://sshop.babalish.com/pet_shop_service/'
  baseURL = 'https://www.humanpetsolution.com/pet_shop_service'
} else {
  // baseURL = 'http://192.168.6.25:8188/'
  // baseURL = 'https://sshop.babalish.com/pet_shop_service/'
  baseURL = 'https://www.humanpetsolution.com/pet_shop_service'
}
// let baseURL = process.env.VUE_APP_ROOT
// let baseURL = 'http://sshop.babalish.com/shop/'
// let baseURL = 'https://sshop.babalish.com/shop_per_admin/'
// let baseURL = 'http://192.168.6.25:8188/'
const service = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  timeout: 120*1000
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    /* if(!config.baseURL) {
      return
      // config.baseURL  = '/admin'
      // config.baseURL  = 'http://47.241.6.166/'
    } */
    // 是否开启全局加载中
    if(config.globalLoading) {
      store.state.loading = true
    }
    if(!config.headers['Content-Type']) {
      config.headers['Content-Type']  = 'application/json;charset=utf-8'
    }  else {
      config.data = qs.stringify(config.data)
    }
    // config.headers['Content-Type']  = 'application/x-www-form-urlencoded'
    if(getToken('HPtoken')) {
      // config.headers['Authorization'] = getToken('HPtoken')
      if (config.method == 'post') { 
        if(config.data) {
          config.data.tokenId = getToken('HPtoken')
          config.data.language = store.state.locale
          // language: this.$store.state.locale,
        } else {
          config.data = {
            tokenId: getToken('HPtoken'),
            language: store.state.locale
          }
        }
      } else {
        if(config.params) {
          config.params.tokenId = getToken('HPtoken')
          config.params.language = store.state.locale
        } else {
          config.params = {
            tokenId: getToken('HPtoken'),
            language: store.state.locale
          }
        }
      }
      
      
    } else {
      if (config.method == 'post') { 
        if(config.data) {
          // config.data.tokenId = getToken('HPtoken')
          config.data.language = store.state.locale
          // language: this.$store.state.locale,
        } else {
          config.data = {
            // tokenId: getToken('HPtoken'),
            language: store.state.locale
          }
        }
      } else {
        if(config.params) {
          // config.params.tokenId = getToken('HPtoken')
          config.params.language = store.state.locale
        } else {
          config.params = {
            // tokenId: getToken('HPtoken'),
            language: store.state.locale
          }
        }
      }
    }
    // config.headers['Access-Control-Allow-Origin'] = '*'
    
    // store.state.loading = false
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    // ElMessage({
    //   message: res.message || '未经身份验证的访问。请先登录。',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code == 401) {
      ElMessage({
        message: res.message || '未经身份验证的访问。请先登录。',
        type: 'error',
        duration: 5 * 1000
      })
    } else if(res.code == 400) {
      ElMessage({
        message: res.message || '操作异常。请检查响应正文以获取详细信息。',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken('HPtoken')
    } else if(res.code == 403) {
      ElMessage({
        message: res.message || '未经授权的访问。请检查您的权限。',
        type: 'error',
        duration: 5 * 1000
      })
      // removeToken('HPtoken')
      // location.reload()
      // localStorage.removeItem('userInfo')
      // store.state.userInfo = {}
      // this.$router.push({path:'/login'})
    } else if(res.code == 500) {
      ElMessage({
          message: res.message || '内部服务器错误 - 意外错误。请检查堆栈跟踪的响应正文。',
          type: 'error',
          duration: 5 * 1000
        })
        return res
        // location.reload()
        // localStorage.removeItem('userInfo')
        // store.state.userInfo = {}
    } else if(res.code == 202) {
      ElMessage({
          message: res.message || '操作仍在执行。请检查任务队列。',
          type: 'error',
          duration: 5 * 1000
        })
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    } else if(res.code == 200 || res.code == 2) {
      store.state.loading = false
      return res
    } else {
      store.state.loading = false
      if(res.code===-1) {
        ElMessage({
          message: res.message || '请重新登录',
          type: 'error',
          duration: 5 * 1000
        })
        removeToken('HPtoken')
        localStorage.removeItem('userInfo')
        store.state.userInfo = {}
        router.push({path:'/login'})
        location.reload()
        return
      }
      if(res.code) {
        ElMessage({
          message: res.msg || '操作失败。',
          type: 'error',
          duration: 5 * 1000
        })
      }
      
      if (res.code === 1) {
        // return res
      } else {
        
      }
      if (res.body && res.body.status && res.body.status && res.body.status.statusCode == 2 && res.body.status.errorDesc === '请重新登录'
      ) {
        ElMessage({
          message: res.body.status.errorDesc || '请重新登录',
          type: 'error',
          duration: 5 * 1000
        })
        removeToken('HPtoken')
        // this.$router.push({path:'/login'})
        // location.reload()
        // localStorage.removeItem('userInfo')
        // store.state.userInfo = {}
      }
      return res
    }
  },
  error => {
    // if(error.response.data.code==2) { // 认领单
    //   return error.response.data
    // }
    // console.log(error.response.data) // for debug
    // if(error.response.data.code == 40001) {
    //   removeToken('Token')
    //   location.reload()
    // }
    // if(error.response.data.code == 40009) {
    //   removeToken('Token')
    //   location.reload()
    // }
    store.state.loading = false
    if(error.response&&error.response.status==424) {
      removeToken('HPtoken')
      // location.reload()
      // this.$router.push({path:'/login'})
      localStorage.removeItem('userInfo')
      store.state.userInfo = {}
    }
    console.log(error.response)
    ElMessage({
      message: error.response&&error.response.data&&error.response.data.msg || '操作失败。',
      type: 'error',
      duration: 5 * 1000
    })
    return error
  }
)

export default service
