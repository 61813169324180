import { createI18n } from 'vue-i18n'
import cn from './zh-cn'
import en from './en'
import ru from './ru'
import store from '@/store'

// import elementEnLocale from 'element-plus/lib/locale/lang/en';
// import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn';

// 创建 i18n
const messages = {
    cn: {
        ...cn,
        // ...elementZhLocale,
    },
    en: {
        ...en,
        // ...elementEnLocale,
    },
    ru: {
        ...ru,
        // ...elementEnLocale,
    }
}

// 获取浏览器当前语言
const getCurrentLanguage = () => {
    // const language = navigator.language
    // console.log(language, '获取浏览器当前语言')
    // const currentLanguage = language.indexOf('zh') !== -1 ? 'cn' : language
    // const currentLanguage = 'en'
    const currentLanguage = store.state.locale
    // localStorage.setItem('language', currentLanguage)
    return currentLanguage
}

// 创建i18n实例
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: localStorage.getItem('language')==='zh'? 'cn': localStorage.getItem('language')|| getCurrentLanguage() || 'en',
    messages: messages
})

export default i18n
