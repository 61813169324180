export default {
    "index.loinText01": "专业的宠物用品寻源网站",
    "index.loinText02": "无忧快递",
    "index.loinText03": "全球",
    "index.loinText04": "海量宠物用品·直达源头·多国家优惠线路",
    "index.retrievePasswordTitle": "请输入您账号关联的邮箱",
    "index.retrievePasswordEmaiTitle": "修改密码",
    "index.home01": "Hello,为您代购/寄送中国好物",
    "index.homeBanner01": "浏览下单",
    "index.homeBanner02": "订单发货",
    "index.homeBanner03": "转运发货",
    "index.homeBanner04": "用户收货",
    "index.homeBannerText01": "浏览商品或提交网址提交代购订单",
    "index.homeBannerText02": "平台接单，为您采购入库提供验货质检服务",
    "index.homeBannerText03": "申请寄送到目的地，选择邮寄方式并支付国际运输费",
    "index.homeBannerText04": "货物到达，祝您购物愉快",
    "index.goodProducts": "好物",
    "index.sellingWell": "热卖",
    "index.recommendation": "推荐",
    "index.Selected": "精选",
    "index.self-support": "自营",
    "index.taobao": "淘宝",
	"index.1688": "1688",
	"index.pdd": "pdd",
	"index.jindong": "京东",
	"index.Tmall": "天猫",
    "index.noData": "没有更多数据了",
    "index.Loading": "加载中...",
    "index.number": "数量",
    "index.ProductRecommendations": "商品推荐",
    "index.StartingPurchase": "件起购",
    "index.OrderSummary": "订单小结",
    "index.GatheringReproducing": "集齐再发",
	"index.arrivalWarehouse": "到仓即发",
    "index.DomesticDirectMail": "自营直邮",
	"index.MoreAddresses": "更多地址",
	"index.ProductInfo": "商品信息",
	"index.freight": "国内运费",
	"index.unitPrice": "单价",
    "index.notes": "备注",
	"index.goodsTotal": "商品总价",
	"index.altogether": "总共",
	"index.IHaveReadPurchaseInstructions": "我已阅读并同意购买须知",
    "index.orderConfirmText": "*货物到达仓库后，立即打包发出，无需等待其他包裹到仓，到货更快。",
    "index.placeOrder": "提交订单",
    "index.LeaveMessage": "留言",
    "index.OptionalFilling": "选填",
    "index.TotalExpenses": "费用合计",
    "index.ValueAddedServiceFee": "增值服务费",
    "index.cashier": "收银台",
    "index.paidText01": "覆盖202个国家或地区 支持25种货币，非美元币种支付时会产生货币兑换和手续费 支持当地合作银行的借记卡和贷记卡",
    "index.paidText02": "全球支持25种货币，非美元币种支付时会产生货币兑换和手续费Visa、MasterCard、AMEX、JCB",
    "index.paidText03": "支持人民币支付，需要绑定中国国内的银行卡",
    "index.residue": "剩余",
    "index.paySuccess": "提交订单成功",
    "index.screen": "筛选",
    "index.ComprehensiveSorting": "综合排序",
    "index.salesVolume": "销量",
	"index.price": "价格",
	"index.Subtotal": "小计",
    "index.noCart": "购物车里空空如也，快去挑选中意好货吧！",
    "index.SelectAll": "全选",
    "index.AccountBalance": "账户余额",
    "index.obligation": "待付款",
    "index.beShipped": "待发货",
    "index.beReceived": "待收货",
    "index.myMessage": "我的消息",
    "index.MyFavorites": "我的收藏",
    "index.ProductName/MainOrderNumber/SubOrderNumber": "商品名称/主单号/子单号",
    "index.OrderStatus": "订单状态",
    "index.startTime": "开始时间",
    "index.EndTime": "结束时间",
    "index.to": "到",
    "index.obligation": "待付款",
    "index.Audit": "待审核",
    "index.Shipped": "已发货",
    "index.Chargeback/Cancellation": "退单/取消",
    "index.totalAmount": "金额总计",
    "index.operation": "操作",
    "index.OrderNumber": "订单编号",
	"index.CreationTime": "创建时间",
    "index.detail": "详情",
    "index.afterSalesDetails": "售后详情",
    "index.dontWant": "不想要了",
	"index.priceBit": "价格有点贵",
	"index.styleSpecificationQuantity": "款式/规格/数量拍错",
	"index.NoNeed": "暂时不需要了",
	"index.other": "其他",
    "index.PaymentMethod": "支付方式",
    "index.SubmissionTime": "提交时间",
    "index.logisticsMessages": "物流消息",
    "index.logisticsCompany": "物流公司",
    "index.LogisticsTrackingNO": "物流单号",
    "index.logisticsInfo": "物流信息",
    "index.deliveryTime": "发货时间",
	"index.orderTracking": "订单跟踪",
	"index.TransactionSerialNumber": "交易流水号",
	"index.ReceivingInfo": "收货信息",
	"index.UserNotes": "用户备注",
	"index.ShippingLogistics": "发货物流",
    "index.AdditionalServiceFee": "额外服务费",
    "index.Service": "服务",
    "index.SupplementaryPaymentAmount": "补款金额",
    "index.ReasonPayment": "补款原由",
    "index.ApplyingServices": "申请服务",
    "index.ApplyAfter-salesServiceText": '温馨提示:无理由退换货费用=寄给卖家的运费+卖家寄来的运费+退换货服务费寄给卖家的运费: 预估运费为6元-25元(具体金额将根据商品重量体积及寄送距离，按照物流公司收费标准收取);卖家寄来的运费: 包邮商品由您承担退回邮费，非包邮商品由您承担发货邮费及退回邮费，具体以卖家回复为准;退换货服务费:详见“代购5天无理由退换货服务规则”',
    "index.applicationType": "申请类型",
	"index.reasonApplication": "申请原因",
	"index.returnQuantity": "退货数量",
	"index.ApplicationDescription": "申请说明",
	"index.ReturnsRefunds": "退货退款",
	"index.exchangeGoods": "换货",
	"index.refundAmount": "退款金额",
	"index.afterSalesDetails": "售后详情",
	"index.AfterSalesProducts": "售后商品",
    "index.RefundOnly": "仅退款",
    "index.UploadCredentials": "上传凭证",
    "index.ProductSpecifications": "商品规格",
    "index.itemPricing": "商品单价",
    "index.AfterSalesInstructions": "售后说明",
    "index.discoveringProductPassword": "发现商品口令",
    "index.isViewDetails": "是否查看详情？",
    "index.ContinueShopping": "继续购物",
    "index.contacts": "联系人",
	"index.phone": "手机号",
	"index.country": "国家",
	"index.Province/Province": "省/州",
	"index.city": "城市",
	"index.detailAddress": "详细地址1",
	"index.detailAddress2": "详细地址2",
	"index.zipCode": "邮编",
	"index.location": "所在地区",
    "index.PleaseSelect": "请选择",
    "index.ModifyAddress": "修改地址",
    "index.newAddress": "新增地址",
    "index.productNameSearch": "商品名称搜索",
    "index.date": "日期",
    "index.isNoData": "暂无数据",
    "index.PostTransactionAmount": "交易后金额",
	"index.TransactionTime": "交易时间",
	"index.TransactionNotes": "交易备注",
	"index.nickname": "昵称",
	"index.Gender": "性别",
	"index.BirthDate": "出生年月",
	"index.WeChatAccount": "微信账号",
	"index.ContactPhoneNumber": "联系电话",
	"index.E-mail": "电子邮箱",
	"index.ModifyAvatar": "修改头像",
	"index.EditInfo": "编辑信息",
	"index.userInfo": "个人信息",
	"index.Language": "语言",
	"index.ChangePassword": "更改密码",
	"index.ClearCache": "清除缓存",
	"index.personalInfo": "个人信息",
	"index.OldPassword": "旧密码",
	"index.NewPassword": "新密码",
    "index.transactionNo": "交易编号",
    "index.Currency": "币种",
    "index.headText": "我们使用Cookie在我们的网站上为您提供尽可能好的体验。有关更多详细信息，包括如何更改cookie设置，请阅读我们的",
    "index.headTextBtn": "cookie政策",
    "index.New": "最新",
    "index.Products": "产品",
    "index.NewProductsText": "探索过去两周内最热门的版本",
    "index.goodsStock": "现货",
    "index.goodsStockText": "从经认证的最低起订量制造商和批发供应商处查找产品",
    "index.brand": "品牌",
    "index.RequestQuotations(RFQ)": "报价请求（RFQ）",
    "index.RequestQuotations(RFQ)Text": "在一分钟内提交RFQ。",
    "index.RequestQuotations(RFQ)Text2": "比较并选择最佳报价！",
    "index.GetQuotationsNow": "立即获取报价",
    "index.Explored": "你探索过吗？",
    "index.homeFoot01": "24小时交付",
    "index.In24Cities": "在24个城市",
    "index.HappyPetParents": "快乐的宠物父母",
    "index.ComplimentaryVetConsult": "免费兽医咨询",
    "index.ForEveryNewMember": "对于每个新成员",
    "index.PetPharmacy": "宠物药房",
    "index.Exclusive": "独家",
    "index.isDefaultAddress": "是否默认地址",
    "index.area": "区",
    "index.QueryLogistics": "查询物流",
    "index.LogOut": "退出登录",
    "index.total": "合计",
    "index.ProductAmount": "商品金额",
    "index.enclosure": "附件",
    "index.name": "姓名",
    "index.company": "公司",
    "index.country": "国家",
    "index.OutboundOrderNo": "出库单号",
    "index.surname": "姓",
    "index.ming": "名",
    "index.accountName": "账号",
    "index.order": "订单",
    "index.ArticleLost": "文章丢失...",
    "index.productDown": "该商品已下架",
    "index.Piece": "件",
    "index.PopularArticles": "热门文章",
    "index.RecommendedArticles": "推荐文章",
    "index.author": "作者",
    "index.LatestNews": "最新动态",
    "index.StayInfo": "了解最新动态",
    "index.news": "消息",
    "index.newsText": "输入产品详细信息，例如颜色、尺寸、材料和其他特定要求。",
    "index.emailAddress": "E-mail地址",
    "index.yourCompanyEmailAddress": "请输入您的公司电子邮件地址",
    "index.TransferNo": "转运单号",

    "menu.overview": "首页",
    "menu.productClass": "商品分类",
    "menu.FreightCalculation": "运费计算",
    "menu.queryLogistics": "查询物流",
    "menu.helpCenter": "帮助中心",
    "menu.aboutUs": "关于我们",
    "menu.productDetail": "商品详情",
    "menu.orderConfirm": "订单确认",
    "menu.orderConfirm1688": "订单确认",
    "menu.bePaid": "支付订单",
    "menu.bePaid1688": "支付订单",
    "menu.paySuccess": "提交成功",
    "menu.search": "搜索",
    "menu.searchType": "搜索",
    "menu.user": "我的",
    "menu.cart": "购物车",
    "menu.favorite": "收藏夹",
    "menu.indentOrder": "代购订单",
    "menu.selfOrder": "我的订单",
    "menu.personalCenter": "个人中心",
    "menu.myAccount": "我的账户",
    "menu.myAddress": "我的地址",
    "menu.myWallet": "我的钱包",
    "menu.MyTracks": "我的足迹",
    "menu.register": "注册",
    "menu.Cat": "猫",
    "menu.Dog": "狗",
    "menu.articleDetails": "文章详情",
    "menu.articleList": "文章资讯",

    "btn.Login": "登录",
    "btn.register": "注册",
    "btn.ForgotPassword": "忘记密码?",
    "btn.HowShop": "如何购物",
    "btn.contactUs": "联系我们",
    "btn.MyOrder": "我的订单",
    "btn.LogisticsFreight": "物流运费",
    "btn.RulesPolicies": "规则与政策",
    "btn.LogOut": "退出登录",
    "btn.confirm": "确定",
    "btn.cancel": "取消",
    "btn.more": "更多",
    "btn.BUYNOW": "立即购买",
	"btn.AddCart": "加入购物车",
	"btn.CollectProducts": "收藏商品",
	"btn.CancelFavorite": "取消收藏",
    "btn.payment": "支付",
    "btn.NextStep": "下一步",
    "btn.PutAway": "收起",
    "btn.Balance": "余额",
    "btn.CreditCard": "信用卡",
    "btn.WeChatPayment": "微信支付",
    "btn.Alipay": "支付宝",
    "btn.platform": "平台",
    "btn.confirm": "确认",
    "btn.comprehensiveSorting": "综合排序",
    "btn.SelfGoods": "自营商品",
    "btn.taobaoGoods": "淘宝商品",
    "btn.1688Goods": "1688商品",
    "btn.purchasingAgentGoods": "代购商品",
    "btn.BatchDeletion": "批量删除",
    "btn.ViewMore": "查看更多",
    "btn.whole": "全部",
    "btn.PendingPayment": "待补款",
    "btn.FreightBePaid": "待付运费",
    "btn.Completed": "已完成",
    "btn.query": "查询",
    "btn.cancellationOrder": "取消订单",
    "btn.after-salesService": "申请售后",
    "btn.payment": "付款",
    "btn.MakeUpPayment": "立即补款",
    "btn.PayShippingFees": "支付运费",
    "btn.ViewLogistics": "查看物流",
    "btn.signFor": "确认收货",
    "btn.CancelAfter-salesService": "取消售后",
    "btn.NotCancelledTemporarily": "暂不取消",
	"btn.ConfirmCancel": "确认取消",
    "btn.Detail": "查看详情",
    "btn.Submit": "提交",
    "btn.onApplyAfter-sales": "再次申请售后",
    "btn.NotViewedNow": "暂不查看",
    "btn.ViewNow": "立即查看",
    "btn.PleaseLogIn": "请登录",
    "btn.viewOrder": "查看订单",
    "btn.homepage": "返回首页",
    "btn.addAddress": "添加地址",
    "btn.DefaultAddress": "默认地址",
    "btn.SetDefault": "设为默认",
    "btn.BatchManagement": "批量管理",
    "btn.BatchDelete": "批量删除",
    "btn.FlowingRecords": "流水记录",
    "btn.UploadAttachments": "单击上传附件",
    "btn.RequestQuotations": "询价",
    "btn.PendingOrders": "待成单",
    "btn.PendingReview": "待审核",
    "btn.ToBeShipped": "待发货",
    "btn.ToBeReceived": "待收货",
    "btn.Signed": "已签收",
    "btn.Completed": "已完成",
    "btn.Canceled": "已取消",
    "btn.ReviewFailed": "审核不通过",
    "btn.followUp": "咨询",
    "btn.publish": "发表",
    "btn.Approved": "审核通过",
    "btn.EditPersonalInfo": "编辑个人信息",
    "btn.ClickDownload": "点击下载",
    "btn.delete": "删除",
    "btn.goLogin": "去登录",
    "btn.upPage": "上一页",
    "btn.nextPage": "下一页",
    "btn.StartOrder": "创建订单",
    "btn.InstantInquiry": "立即询价",
    "btn.SendInquiry": "立即发送询问",
    "btn.SendCode": "发送验证码",
    "btn.RetrieveAgain": "重新获取",

    "message.imgCode": "图形验证码",
    "message.PleaseEmail": "请输入邮箱",
    "message.PleasePassword": "请输入密码",
    "message.PleaseServiceAgreementAndPrivacyPolicy": "请勾选《服务协议》和《隐私政策》",
    "message.PleaseComplete": "请输入完整！",
    "message.IdentifyCodeError": "验证码错误",
    "message.ConfirmPassword": "确认密码",
    "message.isPasswordRule": "密码由6-20位应为字母数字或符号组成",
    "message.TwoPasswordsDoNotMatch": "两次密码不一致！",
    "message.OperationSuccessful": "操作成功",
    "message.isEmailRule": "请输入正确的邮箱",
    "message.prompt": "提示",
    "message.isLogOut": "确定要退出登录吗？",
    "message.PleaseProductLinkQuery": "输入商品链接或名称查询",
    "message.PleaseKeywords": "请输入关键字",
    "message.PleaseSelectSku": "请选择规格",
    "message.PleaseSelectAddress": "请选择地址",
    "message.PleaseCheckBox": "请勾选",
    "message.PleaseReadAgreeServiceAgreement": "请阅读并同意《服务协议》",
    "message.OrderCancelled": "订单已取消",
    "message.PleaseSelectWarehouse": "请选择仓库",
    "message.PleaseSelectCountry": "请选择国家",
    "message.cartText01": "代购订单和自营订单无法同时勾选下单，请分开下单。",
    "message.cartText02": "待支付总价（国际运费需另计）",
    "message.DataLoss": "数据丢失，请联系客服",
    "message.PleaseSelectProduct": "请选择商品",
    "message.PleaseCheckSettled": "请勾选要结算的商品",
    "message.PleaseSelectCancelOrder": "请选择取消订单原因",
    "message.SuccessfullySubmittedWaitingMerchant": "提交成功，等待商家处理",
    "message.isDeleteAddress": "确认删除该条地址吗？",
    "message.PleaseEnter": "请输入",
    "message.PleaseCorrectPhone": "请输入正确的手机号",
    "message.emailConfirm": "请前往邮箱确认",
    "message.EnterKeywordSearch": "输入关键字搜索产品",
    "message.PleaseEnterRequiremen": "请输入您的要求",
    "message.PleaseEnterEmail": "请输入您的电子邮件地址",
    "message.isSigned": "确定签收吗？",
    "message.isCancelOrder": "确定取消订单吗？",
    "message.PleaseEnterYourNeeds": "请输入您的需求",
    "message.PleaseEnterEmail": "请输入您的邮箱地址",
    "message.PleaseEnterContent": "请输入内容",
    "message.isLogOut": "确定要退出登录吗？",
    "message.isEmailRule": "请输入正确的邮箱地址",
    "message.PleaseEnterName": "请输入姓名",
    "message.PleaseEnterCompany": "请输入公司",
    "message.PleaseEnterCountry": "请选择国家",
    "message.PleaseEnterPhone": "请输入手机号",
    "message.resetSuccessPleaseLogin": "重置密码成功，请登录",
    "message.linkHasExpired": "链接已失效",
    "message.linkHasTimedOut": "链接已超时",
    "message.EmailHasBeen": "邮箱已发送，请注意查收",
    "message.VerificationCode": "验证码",
    "message.PleaseEnterVerificationCode": "请输入验证码",
    "message.registeredSuccess": "注册成功",
}